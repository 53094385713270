// .core
import React from "react";
// libraries
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
// styles
import css from "./VideoPage.module.scss";

export default function VideoPage(props: RouteComponentProps) {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        controls={false}
        className={cx(css.root)}
        src={require("assets/videos/cover.mp4")}
        onClick={() => {
          props.history.push("/");
        }}
      ></video>
    </>
  );
}
