// .core
import React from 'react'
import ReactDOM from 'react-dom'
// libraries
import cx from 'classnames'
// styles
import css from './Tooltip.module.scss'
// utils
import { TPos } from 'utils'

interface ITooltipProps {
  /**
   * Type of animation to play for the tooltip
   *
   * @default none
   */
  // animation?: 'fade' | 'scale'     //..    #TODO
  /**
   * Elements to display the tooltip around when hovering over them
   */
  children: React.ReactElement<React.ReactNode>
  className?: string
  /**
   * Content of the tooltip to display
   */
  content: React.ReactNode
  /**
   * Delay of showing the tooltip in miliseconds
   *
   * @default 250
   */
  delay?: number
  /**
   * Margin offset of the tooltip from the element it's wrapping
   *
   * @default 0
   */
  offset?: number
  /**
   * Padding of the tooltip's content
   */
  padding?: number
  /**
   * Position of the tooltip relevant to the element it's wrapping
   *
   * @default 'bottom'
   */
  position?: Extract<TPos, 'top' | 'right' | 'bottom' | 'left'>
}
interface ITooltipState {
  /**
   * Mouse hovering state
   */
  bHovering: boolean
  /**
   * Whether it's the initial render
   * Used to setup the tooltip's positioning, prevents rendering it at [0, 0], allows animations
   */
  bInitRender: boolean
  /**
   * Object holding the position data of the element wrapped by the tooltip
   */
  child: DOMRect | undefined
  /**
   * Object holding the position data of the tooltip
   */
  tooltip: DOMRect | undefined
}

export class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
  static defaultProps: Partial<ITooltipProps> = {
    delay: 250,
    offset: 0,
    position: 'bottom',
  }

  refChild = React.createRef<HTMLDivElement>()
  refTooltip = React.createRef<HTMLDivElement>()

  state: ITooltipState = {
    bHovering: false,
    bInitRender: true,
    child: undefined,
    tooltip: undefined,
  }

  /**
   * Sets the default position of the tooltip - prevents palcing it at [0, 0]
   */
  componentDidMount() {
    this.setState({
      child: this.refChild.current?.getBoundingClientRect(),
      tooltip: this.refTooltip.current?.getBoundingClientRect(),
    })
  }

  /**
   * Updates the position of both `tooltip` and the `child` it's wrapping
   *
   * This way even if the element moves from it's original position the Tooltip's pos.
   * will alway be up-to-date
   */
  onHover = () => {
    setTimeout(
      () => {
        this.setState(prevState => ({
          bInitRender: false,
          bHovering: !prevState.bHovering,
          child: this.refChild.current?.getBoundingClientRect(),
          // #TODO: update the `posTooltip` as well but only when it's dimensions change - it's content
          //        make a object comparison between `prevState.posTooltip` & `this.refTooltip.current?.getBoundingClientRect()`
          //        and update it only when !==
        }))
      },
      this.state.bHovering ? 0 : this.props.delay
    )
  }

  /**
   * Getter method that returns the styling object based on `offset, padding & position` passed from props
   */
  getStyle(): React.CSSProperties {
    const { offset, padding, position } = this.props
    const { child, tooltip } = this.state
    const style: React.CSSProperties = {
      padding,
    }
    /**
     * Top position of Tooltip for left / right positions
     */
    const horizontalTop = child && tooltip ? child.top + child.height / 2 - tooltip.height / 2 : 0
    /**
     * Left position of Tooltip for top / bottom positions
     */
    const verticalLeft =
      child && tooltip ? child.left + child.width / 2 - tooltip.width / 2 : 'unset'

    switch (position) {
      // Renders the tooltip above the wrapped child in the center
      case 'top':
        style.top = (child?.top || 0) - (tooltip?.height || 0) - offset!
        style.left = verticalLeft
        break

      // Renders the tooltip left from the wrapped child in the center
      case 'left':
        style.top = horizontalTop
        style.right = (child?.right || 0) + offset!
        break

      // Renders the tooltip right from the wrapped child in the center
      case 'right':
        style.top = horizontalTop
        style.left = (child?.right || 0) + offset!
        break

      // Renders the tooltip below the wrapped child in the center
      default:
        style.top = (child?.bottom || 0) + offset!
        style.left = verticalLeft
        break
    }

    return style
  }

  render() {
    const { children, className, content } = this.props
    const { bHovering, bInitRender } = this.state

    const PORTAL =
      bHovering || bInitRender
        ? ReactDOM.createPortal(
            <div
              ref={this.refTooltip}
              className={cx(css.wTooltipContent, bHovering && css.hover, className)}
              style={this.getStyle()}>
              {content}
            </div>,
            document.body
          )
        : undefined

    return (
      <>
        {React.cloneElement(children, {
          ref: this.refChild,
          className: css.wTooltip,
          onMouseEnter: this.onHover,
          onMouseLeave: this.onHover,
          ...children.props,
        })}

        {PORTAL}
      </>
    )
  }
}
