// .core
import React from "react";
// assets
import logo from "assets/logos/redBullPresents.svg";
import logoWhite from "assets/logos/redBullPresents@white.svg";
// components
import { HeaderButton } from "../HeaderButton/HeaderButton";
// libraries
import cx from "classnames";
// styles
import css from "./Header.module.scss";
import { Link } from "react-router-dom";
import { DataContext, IDataContext } from "App";
import { Translation } from "../Translation/Translation";

interface IHeaderProps {
  goBack?: { iconName?: string; invertIcon?: boolean };
  hideCart?: boolean;
  hideLogo?: boolean;
  hideLangs?: boolean;
  showCart?: boolean;
  transparentizeLangs?: boolean;
  whiteLogo?: boolean;
  onCartClick?: () => void;
  onGoBack?: () => void;
}

export const Header = ({
  goBack,
  hideCart,
  showCart,
  hideLangs,
  hideLogo,
  transparentizeLangs,
  whiteLogo,
  onGoBack
}: IHeaderProps) => {
  const c = React.useContext(DataContext) as IDataContext;
  let wiggleTimeout: any = null;
  const [cartTotal, setCartTotal] = React.useState<string | false>(
    c.getShoppingCartTotal(0, 0)
  );
  const [shouldWiggle, setShouldWiggle] = React.useState<boolean>(false);

  const [showL, setShowL] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (cartTotal !== c.getShoppingCartTotal(0, 0)) {
      if (!shouldWiggle) {
        clearTimeout(wiggleTimeout);
        wiggleTimeout = setTimeout(() => {
          setShouldWiggle(false);
        }, 500);

        setShouldWiggle(true);
      }
    }
  }, [c, cartTotal]);

  return (
    <>
      <header className={cx(css.root)}>
        <div className={cx(css.col)}>
          {onGoBack ? (
            <HeaderButton
              customIconStyle={{
                marginRight: 5,
                filter: `invert(${
                  goBack && goBack.invertIcon ? 1 : 0
                }00%) grayScale(${goBack && goBack.invertIcon ? 1 : 0}00%)`
              }}
              icon={goBack && goBack.iconName ? goBack.iconName : "chevronLeft"}
              onClick={onGoBack}
            />
          ) : null}
        </div>
        <div className={cx(css.col)}>
          {!hideLogo ? (
            <img
              alt={"RedBull Logo"}
              className={cx(css.logo)}
              src={whiteLogo ? logoWhite : logo}
            />
          ) : null}
        </div>
        <div className={cx(css.col)}>
          {!hideCart && (showCart || c.hasCartItems()) && (
            <Link
              to={"/cart"}
              style={{
                pointerEvents: hideCart || !c.hasCartItems() ? "none" : "all"
              }}
            >
              <HeaderButton
                active={c.hasCartItems()}
                disabled={!c.hasCartItems()}
                icon={"shoppingCart"}
                iconClassName={cx({
                  [css.swingCart]: shouldWiggle
                })}
                text={
                  c.hasCartItems()
                    ? (`${c.trans(
                        "CURRENCY_CHARACTER"
                      )}${c.getShoppingCartTotal(0, 0) || 0}` as string)
                    : undefined
                }
                onClick={() => {}}
              />
            </Link>
          )}
        </div>
        <div
          className={cx(css.languages, {
            [css.active]: showL,
            [css.hide]: hideLangs,
            [css.transparent]: !!transparentizeLangs
          })}
        >
          {!hideLangs ? (
            <>
              <button
                className={cx(css.languageButton)}
                onClick={() => {
                  setShowL(!showL);
                }}
              >
                <Translation name={showL ? "CLOSE" : "LANGUAGES"} />
              </button>
              <div className={cx(css.mainContainer)}>
                <div
                  className={cx(css.languageWrapper, {
                    [css.active]: c.data.locale === "en"
                  })}
                >
                  <img
                    alt=""
                    className={cx(css.language)}
                    src={require("assets/icons/svg/us.png")}
                    onClick={() => {
                      c.setLocale("en");
                    }}
                  />
                </div>
                <div
                  className={cx(css.languageWrapper, {
                    [css.active]: c.data.locale === "cz"
                  })}
                >
                  <img
                    alt=""
                    className={cx(css.language)}
                    src={require("assets/icons/svg/cz.png")}
                    onClick={() => {
                      c.setLocale("cz");
                    }}
                  />
                </div>
                <div
                  className={cx(css.languageWrapper, {
                    [css.active]: c.data.locale === "sk"
                  })}
                >
                  <img
                    alt=""
                    className={cx(css.language)}
                    src={require("assets/icons/svg/sk.png")}
                    onClick={() => {
                      c.setLocale("sk");
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </header>
    </>
  );
};
