// .core
import React from "react";
// components
import { Header } from "components/basic/Header/Header";
// libraries
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
// styles
import css from "./PuzzlePage.module.scss";
// translations
import { Translation } from "components/basic/Translation/Translation";
import { Dialog } from "components";

interface IPuzzle {
  currentIndex: number;
  correctIndex: number;
  isEmpty: boolean;
}

export default function PuzzlePage(props: any) {
  const PUZZLE_SIZE = 3;
  const EMPTY_INDEX = 8;

  const shuffle = (arr: IPuzzle[]): IPuzzle[] => {
    const a = [...arr];

    if (
      props.match.params &&
      (props.match.params as any).difficulty === "ultraSecretEasyOption"
    ) {
      a[5].currentIndex = 8;
      a[8].currentIndex = 5;
    } else {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }

      let correctCount = 0;

      for (let i = 0; i < a.length; i++) {
        if (a[i].correctIndex === i) correctCount++;

        a[i].currentIndex = i;
      }

      if (correctCount > 5) {
        return shuffle(arr);
      }
    }

    return a;
  };

  const getPositionFromIndex = (puzzleIndex: number) => {
    return {
      x: puzzleIndex % PUZZLE_SIZE,
      y: Math.floor(puzzleIndex / PUZZLE_SIZE)
    };
  };

  const getAllowedMovement = (puzzleIndex: number, emptyIndex: number) => {
    const emptyPosition = getPositionFromIndex(emptyIndex);
    const puzzlePosition = getPositionFromIndex(puzzleIndex);

    if (
      emptyPosition.x === puzzlePosition.x + 1 &&
      puzzlePosition.y === emptyPosition.y
    ) {
      return "right";
    } else if (
      emptyPosition.x === puzzlePosition.x - 1 &&
      puzzlePosition.y === emptyPosition.y
    ) {
      return "left";
    } else if (
      emptyPosition.y === puzzlePosition.y + 1 &&
      puzzlePosition.x === emptyPosition.x
    ) {
      return "down";
    } else if (
      emptyPosition.y === puzzlePosition.y - 1 &&
      puzzlePosition.x === emptyPosition.x
    ) {
      return "up";
    } else return null;
  };

  const validatePuzzle = () => {
    let isValid = true;

    puzzles.map(p => {
      if (p.correctIndex !== p.currentIndex) isValid = false;
    });

    return isValid;
  };

  const swapPieces = (searchIndex: number) => {
    if (solved) return;

    let puzzlesCopy = [...puzzles];

    let emptyIndex = 0;
    let emptyCurrentIndex = 0;
    puzzles.map((p, i) => {
      if (p.isEmpty) {
        emptyIndex = i;
        emptyCurrentIndex = p.currentIndex;
      }
    });

    let puzzleIndex = 0;
    puzzles.map((p, i) => {
      if (p.currentIndex === searchIndex) {
        puzzleIndex = i;
      }
    });

    if (getAllowedMovement(searchIndex, emptyCurrentIndex)) {
      let temp: number = puzzlesCopy[puzzleIndex].currentIndex;
      puzzlesCopy[puzzleIndex].currentIndex =
        puzzlesCopy[emptyIndex].currentIndex;
      puzzlesCopy[emptyIndex].currentIndex = temp;

      setPuzzles(puzzlesCopy);
      setMovesCount(movesCount + 1);

      if (!movesCount) stopWatch.start();
      if (validatePuzzle()) {
        setTimeout(() => {
          props.history.push("/infopage/puzzlefinished");
        }, 300);

        setSolved(true);
        stopWatch.pause();
      }
    }
  };

  const [puzzles, setPuzzles] = React.useState<IPuzzle[]>(
    shuffle(
      Array(PUZZLE_SIZE * PUZZLE_SIZE)
        .fill(0)
        .map((_, i) => {
          return {
            currentIndex: i,
            correctIndex: i,
            isEmpty: i === EMPTY_INDEX
          };
        })
    )
  );

  const useStopwatch = () => {
    const [started, setStarted] = React.useState(false);
    const [ms, setMs] = React.useState(0);

    const intervalRef = React.useRef<any>();

    React.useEffect(() => {
      if (started) {
        const startTime = Date.now() - ms;
        const id = setInterval(() => {
          setMs(Date.now() - startTime);
        }, 250);
        intervalRef.current = id;
      }
      return () => clearInterval(intervalRef.current);
    });
    return {
      ms,
      running: started,
      start: () => setStarted(true),
      pause: () => setStarted(false),
      stop: () => {
        setMs(0);
        setStarted(false);
      }
    };
  };

  const getTime = () => {
    const hours = Math.floor(stopWatch.ms / 3600000) % 60;
    const minutes = Math.floor(stopWatch.ms / 60000) % 60;
    const seconds = Math.floor(stopWatch.ms / 1000) % 60;

    return `${hours ? `0${hours}:`.slice(-3) : ""}${`0${minutes}`.slice(
      -2
    )}:${`0${seconds}`.slice(-2)}`;
  };

  const [movesCount, setMovesCount] = React.useState<number>(0);
  const [solved, setSolved] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  const stopWatch = useStopwatch();
  console.log(props);
  return (
    <div className={cx(css.root)}>
      <Header
        onGoBack={() => {
          if (solved || !movesCount) {
            props.history.push("/");
          } else {
            setShowDialog(true);
          }
        }}
      />

      <Dialog
        bOpen={showDialog}
        onSubmit={() => {
          setShowDialog(false);
          props.history.push("/");
        }}
        onToggle={visible => {
          setShowDialog(!!visible);
        }}
        classNameContent={cx(css.dialogContent)}
        title=""
      >
        <h1 className={cx(css.text)}>
          <Translation name={"END_PUZZLE_DIALOG_TITLE"} />
        </h1>
        <p className={cx(css.notice)}>
          <Translation name={"END_PUZZLE_DIALOG_TEXT"} />
        </p>
      </Dialog>

      <main className={cx(css.content)}>
        <div className={cx(css.timerWrapper)}>
          <span className={cx(css.timerBar)}></span>
          <img
            alt=""
            className={cx(css.timerFace, {
              [css.animated]: !!movesCount && !solved
            })}
            src={require("assets/images/timerFace@60.svg")}
          />
          <img
            alt=""
            className={cx(css.timerFace, css.overlay)}
            src={require("assets/images/timerFace@overlay.svg")}
          />
          <span className={cx(css.timeTitle)}>
            <h1 className={cx(css.time)}>{getTime()}</h1>
            <h2 className={cx(css.timeText)}>
              <Translation name={"YOUR_TIME"} />
            </h2>
          </span>
        </div>
        <div className={cx(css.puzzleRoot)}>
          {puzzles.map(puzzle => {
            const position = getPositionFromIndex(puzzle.currentIndex);

            return (
              <div
                key={`puzzleTile_${puzzle.correctIndex}`}
                className={cx(css.puzzleTile, { [css.empty]: puzzle.isEmpty })}
                style={{
                  left: 6 + position.x * 280,
                  top: 6 + position.y * 280,
                  backgroundImage: `url('${require(`assets/images/puzzle@${puzzle.correctIndex +
                    1}-min.jpg`)}')`
                }}
                onClick={() => swapPieces(puzzle.currentIndex)}
              ></div>
            );
          })}
        </div>
      </main>
    </div>
  );
}
