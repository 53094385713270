// .core
import React from 'react'
// components
// import {} from 'components'
// i18n
// import { t } from 'i18n'
// libraries
import cx from 'classnames'
// styles
import css from './DatePicker.module.scss'
// utils
// import {} from 'utils'
// declarations
type TValue = Date | Date[] | string

export interface IDatePickerProps {
  className?: string

  value?: TValue

  onChange?(value: TValue): void
}

/** #TODO: implement / finish up
 * `DatePicker` variant of the input
 */
export const DatePicker = ({ className }: IDatePickerProps) => {
  return <div className={cx(css.wDatePicker, className)}>{/* CONTENT */}</div>
}
