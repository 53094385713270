// .core
import React from "react";
// components
import { APIFallback } from "components/basic/APIFallback/APIFallback";
// content
import { storeItems, IStoreItem } from "content/storeItems";
// libraries
import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps
} from "react-router-dom";
// styles
import "./styles/index.scss";
// translations
import { cz } from "i18n/cz";
import { en } from "i18n/en";
import { sk } from "i18n/sk";

// pages
import CheckoutPage from "pages/CheckoutPage/CheckoutPage";
import HomePage from "pages/HomePage/HomePage";
import PuzzlePage from "pages/PuzzlePage/PuzzlePage";
import InfoPage from "pages/InfoPage/InfoPage";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import SelfiePage from "pages/SelfiePage/SelfiePage";
import ShopPage from "pages/ShopPage/ShopPage";
import ShoppingCartPage from "pages/ShoppingCartPage/ShoppingCartPage";
import VideoPage from "pages/VideoPage/VideoPage";
import TeePage from "pages/TeePage/TeePage";
import MobileForm from "pages/MobileForm/MobileForm";
import { CSSTransition, TransitionGroup } from "react-transition-group";

//
//
//

export const DataContext = React.createContext({});

export interface IDataState {
  apiFallback: {
    withLoader?: boolean;
    success?: boolean;
    title?: string;
    visible?: boolean;
    hasGoHome?: boolean;
    timeout?: number;
    subtitle?: string;
    customBtn?: {
      title: string;
      onClick: () => void;
    };
    customGoBack?: {
      title: string;
      onClick: () => void;
    };
  };
  interval: any;
  formHash: string;
  cartItems: { [key: string]: number };
  locale: string;
  userPhoto: string | boolean;
  userData: { [index: string]: string };
}

export interface IDataContext {
  data: IDataState;
  getShoppingCartItemData: (key: string) => IStoreItem | undefined;
  getShoppingCartItems: () => string[];
  getShoppingCartTotal: (shipping: number, fixed?: number) => string | false;
  hasCartItems: () => boolean;
  setCartItems: (key: string, operation: number) => void;
  setApiFallBack: ({
    success,
    title,
    visible,
    hasGoHome,
    timeout,
    subtitle,
    withLoader
  }: {
    withLoader?: boolean;
    success?: boolean;
    title?: string;
    visible?: boolean;
    hasGoHome?: boolean;
    timeout?: number;
    subtitle?: string;
    customBtn?: {
      title: string;
      onClick: () => void;
    };
    customGoBack?: {
      title: string;
      onClick: () => void;
    };
  }) => void;
  setuserPhoto: (photo: string | boolean) => void;
  setUserData: (userData: { [index: string]: string }) => void;
  setLocale: (locale: string) => void;
  trans: (key: string) => string;
  clearUserData: () => void;
  setFormHash: (hash: string) => void;
  setInt: (interval: any) => void;
  delInt: () => void;
}

export class DataProvider extends React.Component<
  { children: any },
  IDataState
> {
  state = {
    interval: null,
    apiFallback: {
      title: "",
      visible: false,
      success: true,
      hasGoHome: false,
      timeout: 0
    },
    locale: "en",
    cartItems: {},
    userPhoto: false,
    userData: {},
    formHash: ""
  };

  redirectTimeout: any = null;

  hasAvailableItems = () => {
    const keys = Object.keys(this.state.cartItems);
    const availableItems: string[] = [];

    for (const key of keys) {
      if (!!(this.state.cartItems as any)[key]) {
        availableItems.push(key);
      }
    }

    return !!availableItems.length;
  };

  showVideo = () => {
    if (
      !window.location.href.includes("/video") &&
      !window.location.href.includes("/fill-form") &&
      !window.location.href.includes("/order-form") &&
      !window.location.href.includes("/payment") &&
      !window.location.href.includes("/checkout") &&
      !this.state.userPhoto &&
      !this.hasAvailableItems()
    ) {
      window.location.href = "/video";
      clearTimeout(this.redirectTimeout);
    } else {
      console.log("could not redirect because of rules");
    }
  };

  setVideoTimeout = () => {
    this.redirectTimeout = setTimeout(this.showVideo, 60 * 1000);
  };

  updateVideoTimeout = () => {
    clearTimeout(this.redirectTimeout);
    this.setVideoTimeout();
  };

  componentDidMount() {
    this.setVideoTimeout();
    document.addEventListener("click", this.updateVideoTimeout);
    document.addEventListener("touchstart", this.updateVideoTimeout);
    document.addEventListener("touchend", this.updateVideoTimeout);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.updateVideoTimeout);
    document.removeEventListener("touchstart", this.updateVideoTimeout);
    document.removeEventListener("touchend", this.updateVideoTimeout);
  }

  render() {
    return (
      <>
        <DataContext.Provider
          value={
            {
              data: this.state,
              formHash: "",
              delInt: () => {
                console.log("delete interval");
                clearInterval(this.state.interval as any);
              },
              setInt: int => {
                console.log("setting interval: ", int);
                this.setState(prevState => ({
                  ...prevState,
                  interval: int
                }));
              },
              setFormHash: hash => {
                console.log("set form hash");
                this.setState(prevState => ({
                  ...prevState,
                  formHash: hash
                }));
              },
              getShoppingCartItemData: (key: string) => {
                for (const item of storeItems) {
                  if (item.id === key) return item;
                }

                return undefined;
              },
              getShoppingCartItems: () => {
                const keys = Object.keys(this.state.cartItems);
                const availableItems: string[] = [];

                for (const key of keys) {
                  if (!!(this.state.cartItems as any)[key]) {
                    availableItems.push(key);
                  }
                }

                availableItems.sort();

                return availableItems;
              },
              getShoppingCartTotal: (shipping: number = 0, fixed) => {
                const keys = Object.keys(this.state.cartItems).filter(
                  key => (this.state.cartItems as any)[key] !== undefined
                );
                let total = 0;

                if (keys.length) {
                  for (const key of keys) {
                    for (const item of storeItems) {
                      if (item.id === key)
                        total +=
                          item.price *
                          ((this.state.cartItems as any)[key] || 0);
                    }
                  }
                  return `${(total + shipping).toFixed(
                    fixed !== undefined ? fixed : 2
                  )}`;
                } else return false;
              },
              hasCartItems: () => {
                const keys = Object.keys(this.state.cartItems);

                for (const key of keys) {
                  if ((this.state.cartItems as any)[key]) return true;
                }

                return false;
              },
              setApiFallBack: fallBack => {
                console.log("set api fallback", fallBack);
                this.setState(prevState => ({
                  ...prevState,
                  apiFallback: {
                    ...fallBack
                  }
                }));
              },
              setCartItems: (key, operation) => {
                console.log("set cart items");
                let newAmount = (this.state.cartItems as any)[key] || 0;

                if (operation >= 1) {
                  newAmount += operation;
                } else if (operation === 0) {
                  newAmount = undefined;
                } else if (!!(this.state.cartItems as any)[key]) {
                  newAmount--;
                }

                this.setState(prevState => ({
                  ...prevState,
                  cartItems: {
                    ...prevState.cartItems,
                    [key]: newAmount
                  }
                }));
              },
              setuserPhoto: photo => {
                console.log("set user photo");
                this.setState(prevState => ({
                  ...prevState,
                  userPhoto: photo
                }));
              },
              setUserData: data => {
                console.log("set user data");
                this.setState(prevSate => ({
                  ...prevSate,
                  userData: {
                    ...prevSate.userData,
                    ...data
                  }
                }));
              },
              clearUserData: () => {
                console.log("clear user data");
                this.setState(prevSate => ({
                  ...prevSate,
                  userData: {}
                }));
              },
              setLocale: locale => {
                console.log("set locale data");
                this.setState(prevState => ({
                  ...prevState,
                  locale: locale
                }));
              },
              trans: key => {
                let lang: any = en;
                let keys = key.split(".");
                let t: string | undefined = undefined;

                switch (this.state.locale) {
                  case "sk":
                    lang = sk;
                    break;
                  case "cz":
                    lang = cz;
                    break;
                }

                switch (keys.length) {
                  case 1:
                    t = lang[keys[0]];
                    break;
                  case 2:
                    t = lang[keys[0]][keys[1]];
                    break;
                  case 3:
                    t = lang[keys[0]][keys[1]][keys[2]];
                    break;
                  case 4:
                    t = lang[keys[0]][keys[1]][keys[2]][keys[3]];
                    break;
                  default:
                    t = lang[key];
                }

                if (t) return t;
                else return `${this.state.locale}_${key}`;
              }
            } as IDataContext
          }
        >
          {this.props.children}
        </DataContext.Provider>
      </>
    );
  }
}

//
//
//

export const LocaleSetter = (props: RouteComponentProps) => {
  const c = React.useContext(DataContext) as IDataContext;

  React.useEffect(() => {
    if (
      (props.match.params as any).locale !== c.data.locale &&
      props.match &&
      props.match.params &&
      (props.match.params as any).locale
    ) {
      c.setLocale((props.match.params as any).locale);
    }
  }, [c, props.match, props.match.params]);

  return <></>;
};

export default class App extends React.Component {
  render() {
    return (
      <DataProvider>
        <div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <APIFallback />
            <BrowserRouter>
              <Route
                render={props => {
                  return (
                    <TransitionGroup className={"pageWrapper"}>
                      <CSSTransition
                        key={props.location.key}
                        timeout={300}
                        classNames={"fade"}
                      >
                        <Switch location={props.location} key="switch">
                          <Route
                            {...props}
                            exact
                            component={PuzzlePage}
                            path={["/puzzle", "/puzzle/:difficulty"]}
                            key="puzzle"
                          />
                          <Route
                            {...props}
                            exact
                            component={InfoPage}
                            path={["/infopage/:type"]}
                            key="infopage"
                          />
                          <Route
                            {...props}
                            exact
                            component={CheckoutPage}
                            path={["/checkout/:hash"]}
                            key="checkout"
                          />
                          <Route
                            {...props}
                            exact
                            component={PaymentPage}
                            path={["/payment/:hash"]}
                            key="payment"
                          />
                          <Route
                            {...props}
                            exact
                            component={MobileForm}
                            path={["/order-form/:locale/:hash"]}
                            key="order"
                          />
                          <Route
                            {...props}
                            exact
                            component={SelfiePage}
                            path={["/selfie"]}
                            key="selfie"
                          />
                          <Route
                            {...props}
                            exact
                            component={ShopPage}
                            path={["/shop"]}
                            key="shop"
                          />
                          <Route
                            {...props}
                            exact
                            component={ShoppingCartPage}
                            path={["/cart"]}
                            key="cart"
                          />
                          <Route
                            {...props}
                            exact
                            component={VideoPage}
                            path={["/video"]}
                            key="video"
                          />
                          <Route
                            {...props}
                            exact
                            component={TeePage}
                            path={["/tee"]}
                            key="tee"
                          />
                          {/* default */}
                          <Route
                            {...props}
                            component={HomePage}
                            path={["/home", "/", ""]}
                            key="home"
                          />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  );
                }}
              />
            </BrowserRouter>
          </div>
        </div>
      </DataProvider>
    );
  }
}
