// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Icon.module.scss'
// utils
import { TIcon } from 'utils'

interface IIconProps {
  className?: string
  classNameWrapper?: string
  /**
   * Name of the icon
   */
  name: TIcon
  /**
   * content to display within the Icon's tooltip
   */
  tooltip?: React.ReactNode //              #TODO
  onClick?(): void
}

export const Icon = ({ className, classNameWrapper, name, onClick }: IIconProps) => (
  <div className={cx(css.wIcon, onClick && 'gClickable', classNameWrapper)} onClick={onClick}>
    <i data-feather={name} className={cx(`icon-${name}`, className)} />
  </div>
)
