export const cz = {
  // A
  ADD_TO_CART: "Přidať do košíku",
  ADDRESS: "Adresa",

  // B
  BACK: "Zpátky",
  BLUE: "Modrý",
  BLUE_TEE: "Modrý Triko",

  // C
  CANCEL: "Zrušit",
  CARD_EXPIRATION: "Dátum expirace",
  CARD_NUMBER: "Číslo karty",
  CART: "Košík",
  CHECKOUT: "Objednávka",
  CITY: "Město",
  CLOSE: "Zavřít",
  CLOSE_APP: "Zavřít aplikaci",
  CONFIRM: "Potvrdit",
  CONTINUE: "Pokračovat",
  COULDNT_SEND_EMAIL: "Nepodařilo se odeslát e-mail",
  COUNTRY: "Krajina",
  CREATE_SELFIE_TEE: "Vytvořit selfie triko",
  CREATE_TEE: "Vytvořit triko",
  CURRENCY_CHARACTER: "€",
  CVV: "CVV",

  // D
  DATA_OBTAINED: "Data byli úspěšne\r\nsynchronizované s Vašim smartfónem",
  DELIVERED_IN:
    "Vaše objednávka bude doručena za 4 pracovní dni.\r\nZde můžete ",
  DELETE: "Odstranit",
  DELETE_PROMPT: "Opravdu chcete odebrat tuto položku z nákupního košíku?",
  DELIVERY_PRICE: "+ $5 Cena doručení",
  DIALOG: "Dialog",
  DOOR_DELIVERY: "Vaše křídla života\r\ndoručíme přímo vašim dveřím",
  DOWNLOAD: "Stáhnout",

  // E
  EMAIL: "E-mail",
  EMAIL_SENT: "E-mail úspěšne odeslán",
  EMPTY_CART: "Váš nákupní košík je prázdný.",
  END_PUZZLE_DIALOG_TITLE: "Opravdu chcete ukončit tuto hru?",
  END_PUZZLE_DIALOG_TEXT:
    "Aktuálnej stav hry nebude uložen - přijdete tím tedy o možnost výhry plechovky RedBull.",
  ENTER_VALUE: "Zadejte hodnotu",
  ERROR_BOUNDRY: "Ups! Něco se pokazilo.",

  // F
  FORGOTTEN_PASSWORD: "Zapomenuté heslo",

  // G
  GENDER_F: "Dámské",
  GENDER_M: "Pánské",

  // H
  HANDOVER: "Províst",
  HANDOVER_PAYMENT: "Vzdálená Platba",
  HANDOVER_PAYMENT_SUBTITLE: "Synchronizujte Vaše data\r\ns Totem POS",
  HANDOVER_PAYMENT_SYNCED:
    "Vaše data byli\r\nsynchronizována s Totem POS.\r\nČeká sa na Vaše potvrdzení\r\nna obrazovce.",
  HANDOVER_DONE:
    "Objednávka byla vytvořena.\r\nDěkujeme, že využívate našich služeb!\r\n",
  HANDOVER_DONE_INFO:
    "Všechny informace o objednávce Vám zašleme přímo na Vaši e-mailovou adresu.",
  HOME_TILES: {
    PUZZLE: {
      TITLE: "Puzzle",
      SUBTITLE:
        "Zkuste poskládat puzzle v rámci časového limitu a vyhrajte plechovku RedBull-u."
    },
    SHOP: {
      TITLE: "Obchod",
      SUBTITLE:
        "Objednejte si své oblíbené RedBull produkty a mějte je doručeno až domů."
    },
    SELFIE: {
      TITLE: "Selfie Triko",
      SUBTITLE:
        "Udělejte si selfie! Můžete si nechat vytvořit tričko s vaší fotkou, nebo si ji nechat poslat."
    },
    VIDEO: {
      TITLE: "Akční Video",
      SUBTITLE: "Pozrite si naše nejnovější video\nFilipa Polca v Brazílii."
    }
  },
  // I
  // J
  // K
  // L
  LANGUAGES: "Jazyk",
  LOGIN: "Přihlásit se",
  LOGOUT: "Odhlásit se",

  // M
  MAIL_PUZZLE_SUBJECT: "Vyhráli jste plechovku RedBull-u!",
  MAIL_PUZZLE_TEXT:
    "Použijte tento QR kód v jakémkoliv obchodě pro zaplacení za plechovku. Je to jednoduché, stačí jej ukázat obsluze u pokladny.",
  MAIL_SELFIE_SUBJECT: "Zde je Vaša COOL RedBull selfie!",

  // N
  NAME: "Jméno",
  NEXT: "Dále",
  NO_DATA: "Pro tuto sekci neexistují žádné položky", // e.g.: záznamy

  // O
  ORDER_DATA_SENT: "Data úspěšně odeslány",
  ORDER_MAIL_SUBJECT: "Spracování objednávky",
  ORDER_PLACED: "Vaše objednávka byla vytvořena",

  // P
  PASSWORD: "Heslo",
  PAY: "Zaplatit",
  PAYMENT: "Platba",
  PAYMENT_DONE_SUBTITLE:
    "Ze strany vaší banky jsme evidovali Vaši platbu jako uhrazenou. Budeme Vás informovat jakmile odešleme Vaši objednávku.",
  PAYMENT_DONE_TITLE: "Vaše platba\r\nbyla úspěšne uhrazena!",
  PAYMENT_NOTICE:
    "Pole CVV můžete nechat prázdné v závislosti na tom, kde si přejete dokončit platbu.",
  PCS: "ks",
  PHONE: "Telefon",
  PROCEED_TO_CHECKOUT: "Vytvořit objednávku",
  PROCEED_TO_PAYMENT: "Přejít na platbu",
  PUZZLEFINISH_SUBTITLE: `Dejte nám Vaši emailovou adresu a my Vám na ni zašleme QR kód, kterým můžete v obchodě zaplatit za plechovku RedBull-u. Je to jednoduché! Kód stačí ukázat u pokladny v jakémkoliv obchodě.`,
  PUZZLEFINISH_SENT:
    "Zaslali sme Vám QR kód\r\npro Vaši plechovku RedBull zdarma.\r\nUžijte si jí!",
  PUZZLEFINISH_TITLE: "Vyhráli jste\r\nplechovku RedBull-u",

  // Q
  // R
  RED: "Červený",
  RED_TEE: "Červený Triko",
  REGISTER: "Registrovat se",

  // S
  SAVE: "Uložit",
  SCAN_QR: "Naskenujte QR kód a vyplňte formulář z Vašeho smartfónu",
  SELFIE_MAIL_TEXT:
    "Zdravíme, tady je Vaše COOL self od RedBull Teamu! Nezapomeňte ji sdílet na sociálních sítích s Vašimi kamarády!",
  SELFIE_SENT:
    "Vaše COOL selfie\r\nbyla odeslána na Váš e-mail.\r\nNeváhajte ji sdílet s Vašimi kamarády!",
  SELFIE_SUBTITLE:
    "Dejte nám Vaši emailovou adresu a my Vám zašleme Vaši cool selfie!",
  SELFIE_TITLE: "Zaslát selfie\r\nna e-mail",
  SEND_BY_EMAIL: "Zaslát e-mailem",
  SEND_EMAIL: "Odeslát e-mail",
  SKIP: "Přeskočit",
  STORE_ITEMS: {
    REDBULL_4PACK: {
      NAME: "4 Balení",
      LONG_NAME: "RedBull 4 Balení"
    },
    REDBULL_6PACK: {
      NAME: "6 Balení",
      LONG_NAME: "RedBull 6 Balení"
    },
    REDBULL_24PACK: {
      NAME: "24 Balení",
      LONG_NAME: "RedBull 24 Balení"
    }
  },
  SUBMIT: "Potvrdit",
  SUBTOTAL: "Mezisoučet",

  // T
  TAKE_PHOTO: "Udělat fotku",
  TEE: "Triko",
  TEE_SUBTITLE: "Doručíme Vám Křídla Vašeho života\r\npřímo k Vašim dveřím",
  TOTAL: "Spolu",
  TRACK_YOUR_ORDER: "sledovat její stav.",

  // U
  // V
  // W
  WHITE: "Bílé",
  WHITE_TEE: "Bílé Triko",

  // X
  // Y
  YES: "Ano",
  YOUR_TIME: "Váš čas",

  // Z
  ZIP: "PSČ"
};
