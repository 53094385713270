// .core
import React from "react";
// data
import { DataContext, IDataContext } from "App";
// libraries
import cx from "classnames";
// styles
import css from "./APIFallback.module.scss";
import { ButtonDefault } from "../Button/variants/ButtonDefault/ButtonDefault";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export const APIFallback = () => {
  const con = React.useContext(DataContext) as IDataContext;

  const apiFallback = React.useMemo(() => {
    return con.data.apiFallback;
  }, [con.data.apiFallback]);

  const userPhoto = React.useMemo(() => {
    return con.data.userPhoto;
  }, [con.data.userPhoto]);

  const setuserPhoto = React.useMemo(() => {
    return con.setuserPhoto;
  }, [con.setuserPhoto]);

  if (apiFallback.timeout) {
    setTimeout(() => {
      console.log("set fallback from fallback");
      con.setApiFallBack({
        ...apiFallback,
        visible: false,
        timeout: 0,
        withLoader: false
      });
    }, apiFallback.timeout);
  }

  return (
    <div
      className={cx(css.root, {
        [css.visible]: apiFallback.visible
      })}
    >
      <div className={cx(css.badge)}>
        {apiFallback.withLoader && apiFallback.timeout ? (
          <div style={{ position: "absolute" }}>
            <CountdownCircleTimer
              isPlaying
              durationSeconds={(apiFallback.timeout || 0) / 1000}
              initialRemainingTime={(apiFallback.timeout || 0) / 1000}
              trailColor={"#DC0840"}
              colors={[["#D8D8D8"]] as any}
              size={350}
              strokeWidth={10}
            />
          </div>
        ) : null}
        <img
          alt=""
          src={require(`assets/icons/svg/${
            apiFallback.success ? "check" : "close@large"
          }.svg`)}
        />
      </div>
      <h1 className={cx(css.title)}>{apiFallback.title || ""}</h1>
      <h3 className={cx(css.subTitle)}>{apiFallback.subtitle || ""}</h3>

      <span>
        {apiFallback.customGoBack ? (
          <ButtonDefault
            className={cx(css.customButton)}
            label={apiFallback.customGoBack.title}
            onClick={apiFallback.customGoBack.onClick}
          />
        ) : null}
        {apiFallback.customBtn ? (
          <ButtonDefault
            className={cx(css.customButton)}
            label={apiFallback.customBtn.title}
            onClick={apiFallback.customBtn.onClick}
          />
        ) : null}
      </span>

      {apiFallback.hasGoHome ? (
        <button
          className={cx(css.homeBtn)}
          onClick={() => {
            if (!!userPhoto) setuserPhoto(false);
            window.location.href = "/home";
          }}
        >
          <img alt="" src={require("assets/icons/svg/home.svg")} />
        </button>
      ) : null}
    </div>
  );
};
