// .core
import React from 'react'
// components
import { Spinner } from '../../../Loader/variants/Spinner/Spinner'
// libraries
import cx from 'classnames'
// styles
import css from './ButtonDefault.module.scss'

export interface IButtonDefaultProps {
  bDisabled?: boolean
  bLoading?: boolean

  className?: string
  classNameLabel?: string

  label?: string
  type?: 'submit' | 'reset' | 'button'

  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

/**
 * Default button component w/ label & loading spinner support
 */
export const ButtonDefault = ({
  bDisabled,
  bLoading,
  className,
  classNameLabel,
  label,
  type,
  onClick,
}: IButtonDefaultProps) => (
  <button
    className={cx(css.wButtonDefault, bLoading && css.bLoading, className)}
    disabled={bDisabled || bLoading}
    type={type || 'button'}
    onClick={onClick}>
    {/* LOADING SPINNER */}
    {bLoading ? (
      <Spinner className={css.wSpinner} />
    ) : (
      // LABEL
      label && <label className={cx(classNameLabel)}>{label}</label>
    )}
  </button>
)
