// .core
import React from "react";
// data
import { DataContext, IDataContext } from "App";
// components
import { Button, Dialog } from "components";
import { Header } from "components/basic/Header/Header";
// libraries
import cx from "classnames";
import { GenerateQR, SetStatus, FillForm } from "api/endpoints/general";
import { RouteComponentProps } from "react-router-dom";
// styles
import css from "./ShoppingCartPage.module.scss";
// translations
import { Translation } from "components/basic/Translation/Translation";

export default function ShoppingCartPage(props: RouteComponentProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<string | undefined>(
    undefined
  );

  return (
    <div className={cx(css.root)}>
      <Header hideCart onGoBack={props.history.goBack} />
      <div className={cx(css.contentWrapper)}>
        <h1 className={cx(css.title)}>
          <Translation name={"CART"} />
        </h1>
        <DataContext.Consumer>
          {context => {
            const c = context as IDataContext;
            const items = c.getShoppingCartItems();

            return (
              <>
                <Dialog
                  bOpen={!!showDialog}
                  onSubmit={() => {
                    if (showDialog) {
                      c.setCartItems(showDialog, 0);
                      setShowDialog(undefined);

                      setTimeout(() => {
                        if (c.getShoppingCartItems().length === 0) {
                          props.history.goBack();
                        }
                      }, 350);
                    }
                  }}
                  onToggle={() => {
                    setShowDialog(undefined);
                  }}
                  classNameContent={cx(css.dialogContent)}
                  title=""
                >
                  <h1 className={cx(css.text)}>
                    <Translation name={"DELETE_PROMPT"} />
                  </h1>
                </Dialog>

                <div className={cx(css.scrollableContainer)}>
                  <div className={cx(css.innerWrapper)}>
                    {/*  */}
                    {items.length ? (
                      items.map(item => {
                        const data = c.getShoppingCartItemData(item);

                        let parsedId: any = null;

                        try {
                          parsedId = JSON.parse(data!.id);
                        } catch (e) {}

                        return (
                          data && (
                            <div key={data.id} className={cx(css.tile)}>
                              <button
                                className={cx(css.removeButton)}
                                onClick={() => c.setCartItems(item, 0)}
                              >
                                <img
                                  alt=""
                                  src={require("assets/icons/svg/close.svg")}
                                />
                              </button>
                              <img
                                alt=""
                                className={cx(css.photo)}
                                src={data.image}
                              />
                              <div>
                                <h3 className={cx(css.productName)}>
                                  {parsedId ? (
                                    <>
                                      <Translation name={parsedId.color} />{" "}
                                      <Translation name={parsedId.name} />
                                      {" - "}
                                      <Translation
                                        name={`GENDER_${parsedId.gender}`}
                                      />{" "}
                                      ({parsedId.size})
                                    </>
                                  ) : (
                                    <Translation name={data.LONG_NAME} />
                                  )}
                                </h3>
                                <span className={cx(css.controls)}>
                                  <button
                                    className={cx(css.amountButton)}
                                    onClick={() => {
                                      if ((c.data.cartItems[item] || 0) > 1)
                                        c.setCartItems(item, -1);
                                      else setShowDialog(item);
                                    }}
                                  >
                                    -
                                  </button>
                                  <p className={cx(css.amount)}>
                                    {c.data.cartItems[item] || 0}{" "}
                                    <Translation name={"PCS"} />
                                  </p>
                                  <button
                                    className={cx(css.amountButton)}
                                    onClick={() => {
                                      c.setCartItems(item, 1);
                                    }}
                                  >
                                    +
                                  </button>
                                </span>
                              </div>
                              <h4 className={cx(css.priceSum)}>
                                <Translation name={"CURRENCY_CHARACTER"} />
                                {(
                                  (c.data.cartItems[item] || 0) * data.price
                                ).toFixed(2)}
                              </h4>
                            </div>
                          )
                        );
                      })
                    ) : (
                      <p className={cx(css.noData)}>
                        <Translation name={"EMPTY_CART"} />
                      </p>
                    )}
                    {/*  */}
                  </div>
                </div>
                <div
                  className={cx(css.sumWrapper, {
                    [css.hidden]: !c.getShoppingCartTotal(0)
                  })}
                >
                  <h2 className={cx(css.totalSum)}>
                    <Translation name={"SUBTOTAL"} />
                    {` ${c.trans("CURRENCY_CHARACTER")}${c.getShoppingCartTotal(
                      0
                    )}`}
                  </h2>
                  <h5 className={cx(css.deliveryPrice)}>
                    <Translation name={"DELIVERY_PRICE"} />
                  </h5>
                  <Button
                    bDisabled={!c.hasCartItems()}
                    bLoading={loading}
                    label={c.trans("PROCEED_TO_CHECKOUT")}
                    onClick={() => {
                      setLoading(true);
                      GenerateQR().then((res: any) => {
                        setLoading(false);
                        if (res && res.raw && res.raw.qrCode) {
                          const hash = res.raw.qrCode.slice(
                            res.raw.qrCode.lastIndexOf("/") + 1,
                            res.raw.qrCode.length
                          );

                          SetStatus({
                            qrcodehash: hash,
                            step: 1,
                            status: "_"
                          }).then(() => {
                            c.setUserData({});
                            c.setFormHash(hash);
                            props.history.push(`/checkout/${hash}`);
                          });
                        } else {
                          c.setApiFallBack({
                            success: false,
                            visible: true,
                            timeout: 5000,
                            hasGoHome: false,
                            title: c.trans("ERROR_BOUNDRY")
                          });
                        }
                      });
                    }}
                  />
                </div>
              </>
            );
          }}
        </DataContext.Consumer>
      </div>
    </div>
  );
}
