// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Select.module.scss'
// utils
import { ISelectItem } from 'utils'
// declarations
type TValue = ISelectItem | ISelectItem[] // | T | T[]

interface ISelectProps {
  bDisabled?: boolean
  bMulti?: boolean

  className?: string
  classNameLabel?: string
  classNameOption?: string
  classNameSelect?: string

  id?: string
  label?: string
  name?: string
  options?: ISelectItem[] //| T[]
  //   optionId?: string | keyof T
  optionLabel?: string // | keyof T
  value?: TValue

  onSelect?(value: TValue): void
}

/**
 * #TODO:
 *  - implement `react-select`
 *  - finish up
 */
export const Select = ({
  bDisabled,
  bMulti,
  className,
  classNameLabel,
  classNameOption,
  classNameSelect,
  id,
  label,
  name,
  options,
  // optionId,
  optionLabel,
  value,
  onSelect,
}: ISelectProps) => {
  const _onSelect = (option: ISelectItem) => () => {
    if (!bDisabled && onSelect) {
      if (bMulti) {
        const res = value ? [...(value as any[])] : []
        onSelect(res.concat((option as unknown) as any[]))
      }
    }
  }

  return (
    <div className={cx(css.wSelect, className)}>
      {/* LABEL */}
      {label && (
        <label className={cx(css.tLabel, classNameLabel)} htmlFor={id || name}>
          {label}
        </label>
      )}

      {/* SELECT INPUT */}
      <select
        className={cx(css.fSelect, classNameSelect)}
        disabled={bDisabled}
        id={id || name}
        name={name}
        value={value && (value as any)[optionLabel || 'id']}>
        {options?.map((option, index) => (
          <option
            key={'option' + index}
            className={cx(css.bOption, classNameOption)}
            value={option.id}
            onClick={_onSelect(option)}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )
}
