// .core
import React from 'react'
// components
import { Line } from './variants/Line/Line'
import { ISpinnerProps, Spinner } from './variants/Spinner/Spinner'

/**
 * Default `Loader` component using `Spinner`
 */
export class Loader extends React.Component<ISpinnerProps> {
  static Line = Line
  static Spinner = Spinner

  render() {
    return <Spinner {...this.props} />
  }
}
