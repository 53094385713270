// .core
import React from "react";
// api
import { GetFormData, FillForm } from "api/endpoints/general";
// data
import { DataContext, IDataContext, LocaleSetter } from "App";
// libraries
import cx from "classnames";
import { Button, Spinner } from "components";
import { Formik, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";
import Helmet from "react-helmet";
// styles
import css from "./MobileForm.module.scss";
// trans
import { Translation } from "components/basic/Translation/Translation";
// utils
import {
  IOrderBillingData,
  IOrderPaymentData,
  OrderBillingPaymentForm,
  OrderBillingDataForm
} from "utils/forms/CheckoutForm";
import MaskedInput from "react-text-mask";

export default class MobileForm extends React.Component<
  any,
  {
    step: number;
    loading: boolean;
    userData: IOrderBillingData & IOrderPaymentData;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      step: 0,
      loading: true,
      userData: {
        cardnumber: "",
        cardexpiration: "",
        cvv: "",
        address: "",
        city: "",
        country: "",
        email: "",
        name: "",
        phone: "",
        zip: ""
      }
    };
  }

  isMobile = !!(this.props.match.params as any).locale;
  hash = ((this.props.match.params as any).hash as string) || "";
  interval: any = null;

  componentDidMount() {
    this.setCurrentStep();
  }

  componentDidUpdate() {
    if (this.state.step >= 2 && this.state.step <= 4 && !this.interval) {
      this.interval = setInterval(() => {
        GetFormData(this.hash).then((res: any) => {
          if (res && res.raw && (res.raw.step === 3 || res.raw.step === 5)) {
            clearInterval(this.interval);
            this.interval = null;

            this.setState(prevState => ({ ...prevState, step: res.raw.step }));
          }
        });
      }, 1000);
    } else {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  setCurrentStep = () => {
    GetFormData(this.hash).then((res: any) => {
      if (res && res.raw.step) {
        this.setState(prevState => ({
          ...prevState,
          step: res.raw.step,
          loading: false,
          userData: {
            ...res.raw
          }
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          step: 0,
          loading: false
        }));
      }
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>

        <LocaleSetter {...(this.props as any)} />

        <DataContext.Consumer>
          {context => {
            const c = context as IDataContext;

            return (
              <>
                <div className={cx(css.root)}>
                  <header className={cx(css.header)}>
                    <img
                      src={require("assets/logos/redBullPresents.svg")}
                      alt="Logo"
                    />
                  </header>
                  <div className={cx(css.contentWrapper)}>
                    <div>
                      <div className={cx()}>
                        <h1 className={cx(css.title)}>
                          <Translation name={"HANDOVER_PAYMENT"} />
                        </h1>
                        <h2 className={cx(css.subtitle)}>
                          <Translation
                            name={
                              this.state.step === 2 || this.state.step === 4
                                ? "HANDOVER_PAYMENT_SYNCED"
                                : this.state.step === 5
                                ? "HANDOVER_DONE"
                                : "HANDOVER_PAYMENT_SUBTITLE"
                            }
                          />
                          {this.state.step === 5 ? (
                            <Translation name="HANDOVER_DONE_INFO" />
                          ) : null}
                        </h2>
                      </div>
                      {!!(!!this.state.loading && !this.state.step) ? (
                        <div
                          style={{
                            width: "100vw",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center"
                          }}
                        >
                          <Spinner color={"black"} />
                        </div>
                      ) : this.state.step === 1 ? (
                        <Formik
                          enableReinitialize
                          validateOnChange
                          initialValues={{
                            ...OrderBillingDataForm.INITIAL_VALUES,
                            ...c.data.userData
                          }}
                          isInitialValid={
                            Object.keys(c.data.userData).length >= 7
                          }
                          validationSchema={OrderBillingDataForm.VALIDATION}
                          onSubmit={values => {
                            FillForm({
                              ...values,
                              qrcodehash: this.hash,
                              step: 2,
                              cardexpiration: "",
                              cardnumber: ""
                            }).then((res: any) => {
                              if (res && res.status === "success") {
                                this.setState(prevState => ({
                                  ...prevState,
                                  step: 2,
                                  userData: {
                                    ...values,
                                    cardexpiration: "",
                                    cardnumber: ""
                                  }
                                }));
                              }
                            });
                          }}
                        >
                          {({ isSubmitting, isValid, submitForm }) => (
                            <>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"EMAIL"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="email"
                                />
                              </div>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"PHONE"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="phone"
                                />
                              </div>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"NAME"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="name"
                                />
                              </div>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"ADDRESS"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="address"
                                />
                              </div>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"CITY"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="city"
                                />
                              </div>
                              <div className={cx(css.field)}>
                                <label htmlFor="">
                                  <Translation name={"ZIP"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="zip"
                                />
                              </div>
                              <div className={cx(css.field, css.fullWidth)}>
                                <label htmlFor="">
                                  <Translation name={"COUNTRY"} />
                                </label>
                                <Field
                                  className={cx(css.input)}
                                  disabled={isSubmitting}
                                  name="country"
                                />
                              </div>
                              <Button
                                bDisabled={!isValid}
                                className={cx(css.btn)}
                                classNameLabel={cx(css.btnLabel)}
                                label={c.trans("HANDOVER")}
                                onClick={() => {
                                  submitForm();
                                }}
                              />
                            </>
                          )}
                        </Formik>
                      ) : this.state.step === 2 || this.state.step === 4 ? (
                        <div
                          style={{
                            width: "100vw",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            marginTop: 50
                          }}
                        >
                          <Spinner color={"black"} />
                        </div>
                      ) : this.state.step === 3 ? (
                        <>
                          <Formik
                            enableReinitialize
                            validateOnChange
                            initialValues={{
                              cardnumber:
                                c.data.userData.cardnumber ||
                                OrderBillingPaymentForm.INITIAL_VALUES
                                  .cardnumber,
                              cardexpiration:
                                c.data.userData.cardexpiration ||
                                OrderBillingPaymentForm.INITIAL_VALUES
                                  .cardexpiration,
                              cvv:
                                c.data.userData.cvv ||
                                OrderBillingPaymentForm.INITIAL_VALUES.cvv
                            }}
                            isInitialValid={false}
                            validationSchema={
                              OrderBillingPaymentForm.VALIDATION_MOBILE
                            }
                            onSubmit={(values, { setSubmitting }) => {
                              setSubmitting(true);

                              FillForm({
                                qrcodehash: this.hash,
                                address: this.state.userData.address,
                                city: this.state.userData.city,
                                country: this.state.userData.country,
                                email: this.state.userData.email,
                                name: this.state.userData.name,
                                phone: this.state.userData.phone,
                                zip: this.state.userData.zip,
                                cardnumber: values.cardnumber,
                                cardexpiration: values.cardexpiration,
                                cvv:
                                  values.cvv && values.cvv.length
                                    ? values.cvv
                                    : undefined,
                                step: values.cvv && values.cvv.length ? 5 : 4
                              }).then(res => {
                                setSubmitting(false);
                                if (res!.status === "success") {
                                  this.setState(prevState => ({
                                    ...prevState,
                                    step:
                                      values.cvv && values.cvv.length ? 5 : 4
                                  }));
                                }
                                // TODO: else something
                              });
                            }}
                          >
                            {({
                              values,
                              errors,
                              submitForm,
                              isValid,
                              isSubmitting,
                              setFieldValue,
                              initialValues
                            }) => {
                              return (
                                <>
                                  <div className={cx(css.field, css.fullWidth)}>
                                    <label htmlFor="">
                                      <Translation name={"CARD_NUMBER"} />
                                    </label>
                                    <MaskedInput
                                      className={cx(css.input, {
                                        [css.error]: errors.cardnumber
                                      })}
                                      defaultValue={initialValues.cardnumber}
                                      mask={[
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      name="cardnumber"
                                      onChange={e => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className={cx(css.field)}>
                                    <label htmlFor="">
                                      <Translation name={"CARD_EXPIRATION"} />
                                    </label>
                                    <MaskedInput
                                      className={cx(css.input, {
                                        [css.error]: errors.cardexpiration
                                      })}
                                      defaultValue={
                                        initialValues.cardexpiration
                                      }
                                      mask={[/[0-1]/, /\d/, "/", /\d/, /\d/]}
                                      name="cardexpiration"
                                      onChange={e => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className={cx(css.field)}>
                                    <label className={cx(css.nr)} htmlFor="">
                                      <Translation name={"CVV"} />
                                    </label>
                                    <MaskedInput
                                      className={cx(css.input, {
                                        [css.error]: errors.cardexpiration
                                      })}
                                      defaultValue={initialValues.cvv}
                                      mask={[/\d/, /\d/, /\d/]}
                                      name="cvv"
                                      onChange={e => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  <span className={cx(css.fieldInfo)}>
                                    <Translation name="PAYMENT_NOTICE" />
                                  </span>

                                  <DataContext.Consumer>
                                    {context => {
                                      const c = context as IDataContext;

                                      return (
                                        <>
                                          <Button
                                            bDisabled={!isValid}
                                            bLoading={isSubmitting}
                                            className={cx(css.btn)}
                                            classNameLabel={cx(css.btnLabel)}
                                            label={`${c.trans("PAY")}`}
                                            onClick={() => {
                                              if (isValid) {
                                                submitForm();
                                              }
                                            }}
                                          />
                                        </>
                                      );
                                    }}
                                  </DataContext.Consumer>
                                </>
                              );
                            }}
                          </Formik>
                        </>
                      ) : this.state.step === 5 ? (
                        <div
                          style={{
                            width: "100vw",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            textAlign: "center",
                            flexDirection: "column"
                          }}
                        >
                          {/* <Button
                            className={cx(css.btn)}
                            classNameLabel={cx(css.btnLabel)}
                            label={c.trans("CLOSE_APP")}
                            onClick={window.close}
                          /> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </DataContext.Consumer>
      </>
    );
  }
}
