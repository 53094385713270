// .core
import React from 'react'
// components
import { INoDataProps, NoData } from '../../basic/NoData/NoData'
import { Loader } from '../../basic/Loader/Loader'
import { Skeleton } from '../../containers/Skeleton/Skeleton'
// libraries
import cx from 'classnames'
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import { ScrollParams } from 'react-virtualized/dist/commonjs/Grid'
// import { InfiniteLoader } from 'react-virtualized/dist/commonjs/InfiniteLoader'
import { List as ListVirtualized, ListRowProps } from 'react-virtualized/dist/commonjs/List'
// styles
import css from './List.module.scss'
import 'react-virtualized/styles.css'
// declarations
export type IRowData<T> = T & { index: number }

export interface IListProps<T> {
  /**
   * Whether data fetching is in progress, renders a `<Loader.Line />` at the top of `List`
   */
  bLoading?: boolean
  bVirtualized?: boolean
  className?: string
  /**
   * List of data being used by List's items
   */
  collection?: T[]
  /**
   * Custom component rendered per item in collection
   *
   * @default
   * <ListItem />
   */
  item: (rowData?: IRowData<T>) => JSX.Element
  /**
   * Config for what to display when there are no records
   */
  noData?: INoDataProps
  /**
   * Custom height of each row
   *
   * @default '40px'
   */
  rowHeight?: number
  /**
   * Distance from the bottom of the list at which the `onReachEnd` is called
   *
   * @default 0
   */
  threshold?: number
  /**
   * Event fired everytime the `collection` changes in any way (add, reorder, delete)
   */
  // #UNUSED
  //   onChange?(): void
  /**
   * Event fired when scroll reaches end of the list
   *
   * Enabled on when `bVirtualized === true`
   */
  onReachEnd?(): void
  /**
   * Event fired when and item (or group of items) is reordered
   */
  onReorder?(): void
}

/**
 * Component for rendering any type of list (vertical, horizontal, grid based)
 * Supports
 */
export class List<T> extends React.Component<IListProps<T>> {
  static defaultProps = {
    rowHeight: 40,
    treshold: 0,
  }

  refList = React.createRef<ListVirtualized>()

  onScroll = (e: ScrollParams) => {
    const { threshold, onReachEnd } = this.props

    if (e.scrollTop >= e.scrollHeight - e.clientHeight - threshold!) {
      onReachEnd?.()
    }
  }

  // #UNUSED
  /**
   * Event that scrolls to a row based on provided index
   * @param index Index of row to scroll to
   */
  onScrollTo(index: number) {
    this.refList.current?.scrollToRow(index)
  }

  renderNoData = () => <NoData {...this.props.noData} />

  renderRow = ({ index, isScrolling, key, style }: ListRowProps): JSX.Element => {
    const { collection, item } = this.props

    return isScrolling ? (
      <Skeleton.Table bLoading count={1} style={style} />
    ) : (
      <div key={key} style={{ ...style }}>
        {item(collection && { ...collection[index], index })}
      </div>
    )
  }

  renderList = () => {
    const { bVirtualized, collection, item, rowHeight, onReachEnd } = this.props

    return bVirtualized ? (
      <AutoSizer>
        {({ height, width }) => (
          <ListVirtualized
            ref={this.refList}
            className={cx(css.wListVirtualized)}
            height={height}
            noRowsRenderer={this.renderNoData}
            overscanRowCount={5}
            rowCount={collection?.length || 0}
            rowHeight={rowHeight!}
            rowRenderer={this.renderRow}
            width={width}
            onScroll={onReachEnd && this.onScroll}
          />
        )}
      </AutoSizer>
    ) : (
      <div className={css.wListRegular}>
        {collection
          ? collection.map((row, index) => (
              <div key={'row_' + index} style={{ height: rowHeight }}>
                {item({ ...row, index })}
              </div>
            ))
          : this.renderNoData()}
      </div>
    )
  }

  render() {
    const { bLoading, className } = this.props

    return (
      <div className={cx(css.wList, className)}>
        {/* LOADER */}
        <Loader.Line bLoading={bLoading} />

        {/* #CHECK */}
        {/* {onReachEnd ? (
          <InfiniteLoader
            threshold={0}
            isRowLoaded={() => true}
            rowCount={collection?.length}
            loadMoreRows={this.onReachEnd}
          >
            {({ onRowsRendered }) => this.renderList()}
          </InfiniteLoader>
        ) : (
          this.renderList()
        )} */}

        {this.renderList()}
      </div>
    )
  }
}
