// .core
import React from "react";
// components
import {
  ButtonDefault,
  IButtonDefaultProps
} from "../ButtonDefault/ButtonDefault";
// i18n
// import { t } from 'i18n'
// libraries
import cx from "classnames";
// styles
import css from "./ButtonSubmit.module.scss";
import { DataContext, IDataContext } from "App";

interface IButtonSubmitProps extends IButtonDefaultProps {}

/**
 * `Submit` variant of the default button
 */
export const ButtonSubmit = ({
  className,
  label,
  ...otherProps
}: IButtonSubmitProps) => {
  const c = React.useContext(DataContext) as IDataContext;

  return (
    <ButtonDefault
      {...otherProps}
      className={cx(css.wButtonSubmit, className)}
      label={label || c.trans("YES")}
      type="submit"
    />
  );
};
