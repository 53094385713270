// .core
import React from 'react'
// libraries
import cx from 'classnames'
// styles
import css from './Checkbox.module.scss'

interface ICheckboxProps {
  bChecked?: boolean
  bDisabled?: boolean

  className?: string
  classNameInput?: string
  classNameLabel?: string

  id?: string
  label?: string
  name?: string

  onChange?(value: boolean): void
}

/**
 * `Checkbox` variant of the default HTML input
 */
export const Checkbox = ({
  bChecked,
  bDisabled,
  className,
  classNameInput,
  classNameLabel,
  id,
  label,
  name,
  onChange
}: ICheckboxProps) => {
  const _onChange = () => {
    !bDisabled && onChange && onChange(!bChecked)
  }

  return (
    <div className={cx(css.wCheckbox, className)} onClick={_onChange}>
      {/* CHECKBOX INPUT */}
      <input
        checked={bChecked}
        className={cx(classNameInput)}
        id={id || name}
        name={name}
        type="checkbox"
      />

      {/* LABEL */}
      {label && (
        <label className={cx(classNameLabel)} htmlFor={id || name}>
          {label}
        </label>
      )}
    </div>
  )
}
