// .core
import React from "react";
// components
import { Button } from "components";
import { Header } from "components/basic/Header/Header";
// libraries
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
import Webcam from "react-webcam";
// styles
import css from "./SelfiePage.module.scss";

import { DataContext, IDataContext } from "App";

// @ts-ignore
import mergeImages from "merge-images";

export default function SelfiePage(props: RouteComponentProps) {
  const DEFAULT_TIMEOUT = 3;

  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);

  const [timer, setTimer] = React.useState<any>(null);
  const [time, setTime] = React.useState(DEFAULT_TIMEOUT);

  const updateTimer = (
    time: number = DEFAULT_TIMEOUT,
    context: IDataContext
  ) => {
    if (time === 0) {
      if (webcamRef) {
        const p = (webcamRef.current as any).getScreenshot();

        mergeImages(
          [
            p,
            {
              src: require("assets/logos/redBullPresents@white.svg"),
              width: 280,
              height: 126,
              x: window.innerWidth / 2 - 125,
              y: 50
            },
            {
              src: require("assets/images/locationBadge.png"),
              width: 420,
              x: window.innerWidth / 2 - 90,
              y: window.innerHeight - 300
            }
          ],
          {
            height: window.innerHeight,
            width: window.innerWidth
          }
        ).then((b64: any) => {
          context.setuserPhoto(b64);
          console.log(b64);
        });
      }
      setTimer(null);
      setTime(DEFAULT_TIMEOUT);
      return;
    }

    setTime(time);

    setTimer(
      setTimeout(() => {
        updateTimer(time - 1, context);
      }, 1000)
    );
  };

  return (
    <DataContext.Consumer>
      {context => {
        const c = context as IDataContext;
        return (
          <>
            <div className={cx(css.root)}>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 99
                }}
              >
                <Header
                  hideCart
                  transparentizeLangs
                  whiteLogo
                  hideLogo={!!c.data.userPhoto}
                  goBack={{
                    invertIcon: true,
                    iconName: c.data.userPhoto ? "close@large" : undefined
                  }}
                  onGoBack={
                    c.data.userPhoto
                      ? () => c.setuserPhoto(false)
                      : () => {
                          c.setuserPhoto(false);
                          props.history.push("/");
                        }
                  }
                />
              </div>
              {typeof c.data.userPhoto === "string" ? (
                <div className={cx(css.photoWrapper)}>
                  <img alt="" src={c.data.userPhoto || ""} />
                </div>
              ) : (
                <Webcam
                  mirrored
                  className={cx(css.videoWrapper)}
                  ref={webcamRef}
                  audio={false}
                  height={window.innerHeight}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "user",
                    height: 720,
                    width: 720 * (window.innerWidth / window.innerHeight)
                  }}
                  width={window.innerWidth}
                />
              )}
              <footer
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 105,
                  left: 0,
                  height: 100,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {!timer ? (
                  !c.data.userPhoto ? (
                    <Button
                      className={cx(css.button)}
                      label={c.trans("TAKE_PHOTO")}
                      onClick={() => {
                        updateTimer(DEFAULT_TIMEOUT, c);
                      }}
                    ></Button>
                  ) : (
                    <>
                      <Button
                        className={cx(css.buttonLeft)}
                        label={c.trans("SEND_BY_EMAIL")}
                        onClick={() =>
                          props.history.push("/infopage/sendselfie")
                        }
                      />
                      <Button
                        className={cx(css.buttonRight)}
                        label={c.trans("CREATE_TEE")}
                        onClick={() => props.history.push("/tee")}
                      />
                    </>
                  )
                ) : (
                  <>
                    <h2 className={cx(css.time)}>{time}</h2>
                    <div className={cx(css.timerFaceWrapper)}>
                      <span className={cx(css.mainBar)}></span>
                      <img
                        alt=""
                        src={require("assets/images/timerFace@20.svg")}
                      />
                    </div>
                  </>
                )}
              </footer>
            </div>
          </>
        );
      }}
    </DataContext.Consumer>
  );
}
