// .core
import React from 'react'
// components
import { Icon } from '../../basic/Icon/Icon'
import { Loader } from '../../basic/Loader/Loader'
import { INoDataProps, NoData } from '../../basic/NoData/NoData'
// import { Skeleton } from '../../containers/Skeleton/Skeleton'        //      #CHECK
// libraries
import cx from 'classnames'
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import {
  Column,
  ColumnProps,
  Table as TableVirtualized,
  TableCellProps,
  TableCellRenderer,
  TableHeaderProps,
  TableHeaderRenderer,
  TableHeaderRowRenderer,
  SortDirectionType,
} from 'react-virtualized/dist/commonjs/Table'
// styles
import css from './Table.module.scss'
// utils
// import { IFilter, ISort } from 'utils' // #CHECK

interface IFilter<T> {
  key: keyof T
}

interface ISort<T> extends IFilter<T> {
  direction: SortDirectionType
}

export interface IColumns<T> extends Omit<ColumnProps, 'dataKey' | 'width'> {
  /**
   * Column's alignment
   *
   * @default 'center'
   */
  align?: 'start' | 'end'
  /**
   * Whether a column is filterable
   *
   * @default false || undefined
   */
  bFilter?: boolean
  /**
   * Whether a column is sortable
   *
   * @default false || undefined
   */
  bSort?: boolean
  dataKey: keyof T
  /**
   * Render method that overrides the default cell render
   *
   * @default
   * <div className={css.wCell}>
   *    <span>{cellData}</span>
   * </div>
   */
  component?: (cellData: T[keyof T], rowData: T) => JSX.Element
  /**
   * Name of the column
   *
   * @default dataKey
   */
  label?: string
  /**
   * Custom column's width, used for both column's width and maxWidth
   *
   * All columns have `flexGrow={1}` by def. Seting this value will override the flex
   *
   * @default 100
   */
  width?: number
}

export interface ITableProps<T> {
  /**
   * Whether the `Table` is fetching more data, usually changed w/ `onReachEnd`
   * Conditionally renders `Loader.Line` above the table
   */
  bLoading?: boolean
  className?: string
  /**
   * Collection of data fetched from API to display
   */
  collection?: T[]
  /**
   * Columns config
   */
  columns: IColumns<T>[]
  /**
   * Custom `<div />` element to render instead of the default header
   */
  header?: React.ReactElement<HTMLDivElement>
  /**
   * Custom height of the header - should be identical or slightly bigger that `rowHeight`
   *
   * @default '40px'
   */
  headerHeight?: number
  /**
   * Config for what to display when there are no records
   */
  noData?: INoDataProps
  /**
   * Custom height of each row
   *
   * @default '40px'
   */
  rowHeight?: number
  /**
   * Distance from the bottom of the list at which the `onReachEnd` is called
   *
   * @default 0
   */
  threshold?: number
  onFilter?(filterParams: IFilter<T>): void
  /**
   * Event method called whenever user reaches bottom of the scrollable area
   * Used for fetching more data (next page)
   */
  onReachEnd?(): void
  onRowClick?(): void
  onRowDoubleClick?(): void
  onRowRightClick?(): void
  onSort?(sortParams: ISort<T>): void
}

interface ITableState {
  sortDirection: SortDirectionType
}

export class Table<T> extends React.Component<ITableProps<T>, ITableState> {
  static defaultProps = {
    headerHeight: 40,
    rowHeight: 40,
    threshold: 0,
  }

  refList = React.createRef<TableVirtualized>()

  state: ITableState = { sortDirection: 'ASC' }

  /**
   * Event method called when a filterable header column is clicked
   */
  onFilter = (key: keyof T) => () => {
    const { onFilter } = this.props

    onFilter?.({ key })
  }

  /**
   * Event method called when scrolling through the `Table` - handles fetching next page upon reaching Table's end
   */
  onScroll = (e: any /* ScrollEventData */) => {
    const { threshold, onReachEnd } = this.props

    if (e.scrollTop >= e.scrollHeight - e.clientHeight - threshold!) {
      onReachEnd?.()
    }
  }

  // #UNUSED
  /**
   * Event method that scrolls to a row based on provided index
   * @param index Index of row to scroll to
   */
  onScrollTo(index: number) {
    this.refList.current?.scrollToRow(index)
  }

  /**
   * Event method called when a sortable header column is clicked
   *
   * @param sortBy - column's `dataKey: keyof T`
   * @param sortDirection - `'ASC' | 'DESC'`
   *
   * #NOTE: Had to redesign the param structure into custom one for more easier usage
   */
  onSort = ({ sortBy: key }: { sortBy: string }) => {
    const { onSort } = this.props

    this.setState(
      ({ sortDirection: prevSortDirection }) => ({
        sortDirection: prevSortDirection === 'ASC' ? 'DESC' : 'ASC',
      }),
      () => {
        onSort?.({ key: key as keyof T, direction: this.state.sortDirection })
      }
    )
  }

  /**
   * Getter method for retrieving row data
   *
   * Technically this is not represented on UI in any way, however the `TableVirtualized` has this marked as required
   * and won't work w/o it.
   */
  rowGetter = ({ index }: { index: number }) => this.props.collection?.[index] ?? undefined

  /**
   * Render method for displaying custom header passed via `header` prop
   *
   * Clones exactly what's passed w/ additional "internal" details (`height, width`)
   */
  renderHeader = (
    width: number
  ): TableHeaderRowRenderer | undefined => (/* {}: TableHeaderRowProps */) => {
    const { header, headerHeight: height } = this.props

    return header && React.cloneElement(header as any, { style: { height, width } })
  }

  /**
   * Render method for individual column's header
   */
  renderHeaderForColumn = (colIndex: number): TableHeaderRenderer => ({
    dataKey,
  }: TableHeaderProps) => {
    const { headerHeight, rowHeight, columns, onFilter, onSort } = this.props
    const { sortDirection } = this.state
    const col = columns[colIndex]

    return (
      <div className={css.wHeaderCell} style={{ height: headerHeight || rowHeight }}>
        {/* LABEL */}
        <span>{col.label || dataKey}</span>

        {/* SORT */}
        {/* #NOTE: as of now, the feather icons are broken, the required data-feather prop within Icon doesnt update hence the icon wont re-render, they're only temp. so who cares, this feature works */}
        {onSort && col.bSort && (
          <Icon name={sortDirection === 'ASC' ? 'chevron-down' : 'chevron-up'} />
        )}

        {/* FILTER */}
        {onFilter && col.bFilter && (
          <Icon name="filter" onClick={this.onFilter(dataKey as keyof T)} />
        )}
      </div>
    )
  }

  /**
   * Render method for Table's individual cells
   *
   * Defaults to `span`, this can be overwritten by defining `component` prop within Table's `columns` prop (config)
   */
  renderCell: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowData /* ,dataKey, rowIndex */,
  }: TableCellProps) => {
    const { columns } = this.props
    const col = columns[columnIndex]

    return (
      col.component?.(cellData, rowData) || (
        <div className={css.wCell} style={{ justifyContent: col.align && 'flex-' + col.align }}>
          {/* #NOTE: `cellData === (collection[rowIndex] as any)[dataKey])` */}
          <span>{cellData}</span>
        </div>
      )
    )
  }

  /**
   * Render method for displaying info message when there are no records within the `Table`
   */
  renderNoData = () => <NoData {...this.props.noData} />

  render() {
    const {
      bLoading,
      columns,
      className,
      collection,
      header,
      headerHeight,
      rowHeight,
      onRowClick,
      onRowDoubleClick,
      //   onRowRightClick
    } = this.props

    return (
      <div className={cx(css.wTable, className)}>
        {/* LOADER */}
        <Loader.Line bLoading={bLoading} />

        {/* TABLE */}
        <AutoSizer>
          {({ height, width }) => (
            <TableVirtualized
              ref={this.refList}
              headerHeight={headerHeight!}
              headerRowRenderer={header && this.renderHeader(width)} //      #CHECK w/o `header &&` it wont render the def. header even when the method returns undefined
              height={height}
              noRowsRenderer={this.renderNoData}
              overscanRowCount={5}
              rowCount={collection?.length || 0}
              rowGetter={this.rowGetter}
              rowHeight={rowHeight!}
              sort={this.onSort as any}
              width={width}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              //   onRowRightClick={onRowRightClick}    // #NOTE: is in docs but not in .d.ts: https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md#headerrowrenderer
              onScroll={this.onScroll}>
              {columns.map((col, colIndex) => (
                <Column
                  key={col.dataKey + '_' + colIndex}
                  cellRenderer={this.renderCell}
                  dataKey={col.dataKey as string}
                  disableSort={!col.bSort}
                  flexGrow={1}
                  headerRenderer={this.renderHeaderForColumn(colIndex)}
                  maxWidth={col.width}
                  style={{ border: '1px solid blue' }} //                       #DELETE
                  width={col.width || 100}
                />
              ))}
            </TableVirtualized>
          )}
        </AutoSizer>
      </div>
    )
  }
}
