// .core
import React from "react";
// components
import { Header } from "components/basic/Header/Header";
import { HomeTile } from "components/basic/HomeTile/HomeTile";
// libraries
import cx from "classnames";
// styles
import css from "./HomePage.module.scss";
// data
import { DataContext, IDataContext } from "App";

export default function HomePage() {
  return (
    <div className={cx(css.root)}>
      <Header />
      <DataContext.Consumer>
        {context => {
          const c = context as IDataContext;

          return (
            <>
              <div className={cx(css.tiles)}>
                <HomeTile
                  image={require("../../assets/images/homeTile@puzzle-min.jpg")}
                  linkTo={"puzzle"}
                  subtitle={c.trans("HOME_TILES.PUZZLE.SUBTITLE")}
                  title={c.trans("HOME_TILES.PUZZLE.TITLE")}
                />
                <HomeTile
                  image={require("../../assets/images/homeTile@shop-min.jpg")}
                  linkTo={"shop"}
                  subtitle={c.trans("HOME_TILES.SHOP.SUBTITLE")}
                  title={c.trans("HOME_TILES.SHOP.TITLE")}
                />
                <HomeTile
                  image={require("../../assets/images/homeTile@selfie-min.jpg")}
                  linkTo={"selfie"}
                  subtitle={c.trans("HOME_TILES.SELFIE.SUBTITLE")}
                  title={c.trans("HOME_TILES.SELFIE.TITLE")}
                />
                <HomeTile
                  image={require("../../assets/images/homeTile@video-min.jpg")}
                  linkTo={"video"}
                  subtitle={c.trans("HOME_TILES.VIDEO.SUBTITLE")}
                  title={c.trans("HOME_TILES.VIDEO.TITLE")}
                />
              </div>
            </>
          );
        }}
      </DataContext.Consumer>
    </div>
  );
}
