// .core
import React from "react";
// libraries
import cx from "classnames";
import { Link } from "react-router-dom";
// styles
import css from "./HomeTile.module.scss";

interface IHomeTileProps {
  image: string;
  linkTo: string;
  subtitle: string;
  title: string;
}

export const HomeTile = ({
  image,
  linkTo,
  subtitle,
  title
}: IHomeTileProps) => {
  return (
    <div className={cx(css.root)}>
      <Link to={linkTo}>
        <div
          className={cx(css.contentWrapper)}
          style={{ backgroundImage: `url('${image}')` }}
        >
          <main>{/* <img alt={title} src={image} /> */}</main>
          <footer className={cx(css.textContent)}>
            <h2 className={cx(css.title)}>{title}</h2>
            <p className={cx(css.subtitle)}>{subtitle}</p>
          </footer>
        </div>
      </Link>
    </div>
  );
};
