// .core
import React from "react";
// api
import { SendMail, GenerateImgPath } from "api/endpoints/general";
// components
import { Button } from "components";
import { Header } from "components/basic/Header/Header";
// data
import { DataContext, IDataContext } from "App";
// libraries
import cx from "classnames";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { RouteComponentProps } from "react-router-dom";
// styles
import css from "./InfoPage.module.scss";
// trans
import { Translation } from "components/basic/Translation/Translation";

const base64Img = require("base64-img");

export default function InfoPage(props: RouteComponentProps) {
  const type = (props.match.params as any).type;
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <DataContext.Consumer>
      {context => {
        const c = context as IDataContext;

        return (
          <>
            <div className={cx(css.root)}>
              <Header hideCart />
              <div className={cx(css.contentWrapper)}>
                <div className={cx(css.content)}>
                  <h1 className={cx(css.title)}>
                    {type === "puzzlefinished"
                      ? c.trans("PUZZLEFINISH_TITLE")
                      : type === "sendselfie"
                      ? c.trans("SELFIE_TITLE")
                      : type === "paymentdone"
                      ? c.trans("PAYMENT_DONE_TITLE")
                      : null}
                  </h1>
                  <p className={cx(css.subtitle)}>
                    {type === "puzzlefinished"
                      ? c.trans("PUZZLEFINISH_SUBTITLE")
                      : type === "sendselfie"
                      ? c.trans("SELFIE_SUBTITLE")
                      : type === "paymentdone"
                      ? c.trans("PAYMENT_DONE_SUBTITLE")
                      : null}
                  </p>
                  {type === "puzzlefinished" || type === "sendselfie" ? (
                    <Formik
                      validateOnChange
                      initialValues={{ email: "" }}
                      isInitialValid={false}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .required()
                          .email()
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);

                        let pathRes: any = undefined;
                        if (typeof c.data.userPhoto === "string") {
                          pathRes = await GenerateImgPath({
                            base64: c.data.userPhoto
                          });
                        }

                        SendMail({
                          html_content:
                            type === "sendselfie"
                              ? encodeURI(
                                  `<div style="flex-direction: column; margin-top: 15px; display: flex; justify-content: center; align-items: center;"><div><p style="max-width: 80%; text-align: center; margin: auto;">${c.trans(
                                    "SELFIE_MAIL_TEXT"
                                  )}</p><img src="${
                                    pathRes.raw.img
                                  }" ${`style="height: 480px; width: 270px; object-fit: cover; object-position: center; margin-top: 50px; margin-bottom: 50px; border-radius: 10px; margin-left: auto; margin-right: auto;"`}/><a href="${
                                    pathRes.raw.img
                                  }" download="RedBull_Selfie" style="
                                  border: 0px; border-radius: 10px; 
                                  background-color: #dd083e; font-size: 16px; 
                                  color: white; height: 45px; 
                                  padding: 0 15px; margin: 25px auto; 
                                  text-decoration: none; min-height: 45px; 
                                  display: flex; width: 120px; justify-content: center; line-height: 45px;
                                  "><span style="width: 120px; text-align: center;">${c.trans(
                                    "DOWNLOAD"
                                  )}</span></a></div></div>`
                                )
                              : encodeURI(
                                  `<div style=""><p style="max-width: 80%; text-align: center; margin: auto;">${c.trans(
                                    "MAIL_PUZZLE_TEXT"
                                  )}</p><img src="https://www.rb.ambitas.sk/qrCode.png" style="height: 200px; width: 200px; object-fit: cover; object-position: center; margin-top: 50px; margin-bottom: 50px; margin-left: auto; margin-right: auto;" /></div></div>`
                                ),
                          send_to: values.email,
                          title:
                            type === "puzzlefinished"
                              ? c.trans("MAIL_PUZZLE_SUBJECT")
                              : type === "sendselfie"
                              ? c.trans("MAIL_SELFIE_SUBJECT")
                              : "no_subject",
                          subject:
                            type === "puzzlefinished"
                              ? c.trans("MAIL_PUZZLE_SUBJECT")
                              : type === "sendselfie"
                              ? c.trans("MAIL_SELFIE_SUBJECT")
                              : "no_subject"
                        }).then(res => {
                          if (res!.message.includes("success")) {
                            c.setApiFallBack({
                              title: c.trans(
                                type === "puzzlefinished"
                                  ? "PUZZLEFINISH_SENT"
                                  : type === "sendselfie"
                                  ? "SELFIE_SENT"
                                  : "EMAIL_SENT"
                              ),
                              hasGoHome: true,
                              timeout: 0,
                              visible: true,
                              success: true,
                              customBtn:
                                type === "sendselfie"
                                  ? {
                                      title: c.trans("CREATE_SELFIE_TEE"),
                                      onClick: () => {
                                        c.setApiFallBack({ visible: false });
                                        props.history.push("/tee");
                                      }
                                    }
                                  : undefined,
                              customGoBack:
                                type === "sendselfie"
                                  ? {
                                      title: c.trans("BACK"),
                                      onClick: () => {
                                        c.setApiFallBack({ visible: false });
                                        props.history.push("/selfie");
                                      }
                                    }
                                  : undefined
                            });
                          } else {
                            c.setApiFallBack({
                              title: c.trans("COULDNT_SEND_EMAIL"),
                              hasGoHome: true,
                              timeout: 5000,
                              visible: true,
                              success: false
                            });
                          }

                          setLoading(false);
                          setSubmitting(false);
                        });
                      }}
                    >
                      {({
                        isSubmitting,
                        submitForm,
                        isValid,
                        setSubmitting
                      }) => {
                        return (
                          <>
                            <div className={cx(css.field)}>
                              <label htmlFor="name">
                                <Translation name={"ADDRESS"} />
                              </label>
                              <Field className={cx(css.input)} name={"email"} />
                            </div>
                            <Button
                              bLoading={isSubmitting || loading}
                              className={cx(css.submitBtn)}
                              bDisabled={!isValid}
                              label={c.trans("SEND_EMAIL")}
                              onClick={() => {
                                if (isValid) {
                                  setLoading(true);
                                  setSubmitting(true);
                                  submitForm();
                                }
                              }}
                            />
                          </>
                        );
                      }}
                    </Formik>
                  ) : null}
                  <button
                    className={cx(css.homeBtn)}
                    onClick={() => {
                      if (c.data.userPhoto) c.setuserPhoto(false);
                      props.history.push("/home");
                    }}
                  >
                    <img alt="" src={require("assets/icons/svg/home.svg")} />
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </DataContext.Consumer>
  );
}
