export const en = {
  // A
  ADD_TO_CART: "Add to Cart",
  ADDRESS: "Address",

  // B
  BACK: "Back",
  BLUE: "Blue",
  BLUE_TEE: "Blue Tee",

  // C
  CARD_EXPIRATION: "Card expiration date",
  CARD_NUMBER: "Card number",
  CANCEL: "Cancel",
  CART: "Cart",
  CVV: "CVV",
  CHECKOUT: "Checkout",
  CITY: "City",
  CLOSE: "Close",
  CLOSE_APP: "Close the App",
  CONFIRM: "Confirm",
  CONTINUE: "Continue",
  COULDNT_SEND_EMAIL: "Couldn't send e-mail",
  COUNTRY: "Country",
  CREATE_TEE: "Create a Tee",
  CREATE_SELFIE_TEE: "Create a Selfie Tee",
  CURRENCY_CHARACTER: "$",

  // D
  DATA_OBTAINED: "Data have been\r\nsuccessfully synced with your phone",
  DELIVERED_IN:
    "Your order will be delivered in 4 working days.\r\nHere you can ",
  DELETE: "Delete",
  DELETE_PROMPT:
    "Do you really want to remove this item from the shopping cart?",
  DELIVERY_PRICE: "+ $5 Delivery Price",
  DIALOG: "Dialog",
  DOOR_DELIVERY: "We will deliver your wings of life\r\nstraight to your doors",
  DOWNLOAD: "Download",

  // E
  EMAIL: "E-mail",
  EMAIL_SENT: "E-mail sent successfully",
  EMPTY_CART: "Your shopping cart is empty.",
  END_PUZZLE_DIALOG_TITLE: "Dou you really want to quit this game?",
  END_PUZZLE_DIALOG_TEXT:
    "Your progress and so the chances for winning a can of RedBull will be lost.",
  ENTER_VALUE: "Enter a value",
  ERROR_BOUNDRY: "Whops! Something went wrong.",

  // F
  FORGOTTEN_PASSWORD: "Forgotten Password",

  // G
  GENDER_F: "Women",
  GENDER_M: "Men",

  // H
  HANDOVER: "Handover",
  HANDOVER_PAYMENT: "Handover Payment",
  HANDOVER_PAYMENT_SUBTITLE: "Synchronise your data\r\nwith the Totem POS",
  HANDOVER_PAYMENT_SYNCED:
    "Your data have been\r\nsynced with Totem POS.\r\nWaiting for your confirmation\r\non the screen.",
  HANDOVER_DONE: "Order has been placed.\r\nThanks for using our services!\r\n",
  HANDOVER_DONE_INFO:
    "We will send you all of the information about this order directly to your mail account.",
  HOME_TILES: {
    PUZZLE: {
      TITLE: "Play Puzzle",
      SUBTITLE:
        "Try to put together Puzzle untill the time limit and win can of RedBull."
    },
    SHOP: {
      TITLE: "Shop Now",
      SUBTITLE:
        "Order your favourite RedBull products\nand deliver to your home."
    },
    SELFIE: {
      TITLE: "Selfie 'Tee'",
      SUBTITLE:
        "Take selfie! Complete design with\nAR components and print your own Tee."
    },
    VIDEO: {
      TITLE: "Action Video",
      SUBTITLE: "Check our the newest ride video\nof Filip Polc in Brasil."
    }
  },
  // I
  // J
  // K
  // L
  LANGUAGES: "Language",
  LOGIN: "Login",
  LOGOUT: "Logout",

  // M
  MAIL_PUZZLE_SUBJECT: "You have just won a can of RedBull!",
  MAIL_PUZZLE_TEXT:
    "Use this code anywhere in store to pay your can of RedBull. It’s easy, just show this code to staff on cash desk.",
  MAIL_SELFIE_SUBJECT: "Here is your cool RedBull selfie!",

  // N
  NAME: "Name",
  NEXT: "Next",
  NO_DATA: "There are no records for this section",

  // O
  ORDER_DATA_SENT: "Data successfully sent",
  ORDER_MAIL_SUBJECT: "Processing order",
  ORDER_PLACED: "Your order was placed",

  // P
  PASSWORD: "Password",
  PAY: "Pay",
  PAYMENT: "Payment",
  PAYMENT_DONE_SUBTITLE: `Your bank has just confirmed your payment. We will notify you as soon as we ship out your order.`,
  PAYMENT_DONE_TITLE: `Your payment\r\nwas successfully done!`,
  PAYMENT_NOTICE:
    "You may or may not leave the CVV field empty depeding on where you want to finish this transaction.",
  PCS: "pcs",
  PHONE: "Phone",
  PROCEED_TO_CHECKOUT: "Proceed to checkout",
  PROCEED_TO_PAYMENT: "Proceed to payment",
  PUZZLEFINISH_SUBTITLE: `Give us your e-mail address and we’ll send you an QR code. Use this code anywhere in store to pay your can of RedBull. It’s easy, just show this code to staff on cash desk.`,
  PUZZLEFINISH_SENT:
    "We 've sent you a QR\r\nfor your free can of RedBull.\r\nEnjoy!",
  PUZZLEFINISH_TITLE: "You have won\r\na can of RedBull",

  // Q
  // R
  RED: "Red",
  RED_TEE: "Red Tee",
  REGISTER: "Register",

  // S
  SAVE: "Save",
  SCAN_QR: "Scan QR code and fill the form in your smartphone",
  SELFIE_MAIL_TEXT:
    "Hi, here is your cool selfie straight from RedBull Team! Please do not forget to share it with your friends on your social media!",
  SELFIE_SENT:
    "Your cool selfie\r\nwas sent to your e-mail.\r\nShare it with your friends!",
  SELFIE_SUBTITLE:
    "Give us your e-mail address and we’ll send you your cool selfie!",
  SELFIE_TITLE: "Send selfie\r\nby e-mail",
  SEND_BY_EMAIL: "Send by e-mail",
  SEND_EMAIL: "Send e-mail",
  SKIP: "Skip",
  STORE_ITEMS: {
    REDBULL_4PACK: {
      NAME: "4 Pack",
      LONG_NAME: "RedBull 4 Pack"
    },
    REDBULL_6PACK: {
      NAME: "6 Pack",
      LONG_NAME: "RedBull 6 Pack"
    },
    REDBULL_24PACK: {
      NAME: "24 Pack",
      LONG_NAME: "RedBull 24 Pack"
    }
  },
  SUBMIT: "Submit",
  SUBTOTAL: "Subtotal",

  // T
  TAKE_PHOTO: "Take a Photo",
  TEE: "Tee",
  TEE_SUBTITLE: "We will deliver your wings of life\r\nstraight to your doors",
  TOTAL: "Total",
  TRACK_YOUR_ORDER: "track your order.",

  // U
  // V
  // W
  WHITE: "White",
  WHITE_TEE: "White Tee",

  // X
  // Y
  YES: "Yes",
  YOUR_TIME: "Your time",

  // Z
  ZIP: "Zip"
};
