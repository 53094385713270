// .core
import React from "react";
// data
import { DataContext, IDataContext } from "App";
import { storeItems } from "content/storeItems";
// libraries
// @ts-ignore
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
// @ts-ignore
import { mod } from "react-swipeable-views-core";
// styles
import css from "./ShopSwiper.module.scss";
import "swiper/swiper.scss";
// trans
import { Translation } from "../Translation/Translation";
import { Button } from "../Button/Button";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export const ShopSwiper = (props: RouteComponentProps) => {
  const [currentIndex, updateCurrentIndex] = React.useState<number>(0);
  const [amount, setAmount] = React.useState<number>(1);

  const renderSlide = (params: any) => {
    const { index, key } = params;

    const trueIndex = mod(index, storeItems.filter(i => !!i.available).length);

    const id = storeItems[trueIndex].id;

    return (
      <div
        key={key}
        className={cx(css.slide, {
          [css.active]: currentIndex === index
        })}
        onClick={() => {
          updateCurrentIndex(index);
        }}
      >
        <img alt={storeItems[trueIndex].id} src={storeItems[trueIndex].image} />
        <h3 className={cx(css.title)}>
          <Translation name={storeItems[trueIndex].NAME} />
        </h3>
        <h4 className={cx(css.price)}>
          <Translation name={"CURRENCY_CHARACTER"} />
          {storeItems[trueIndex].price.toFixed(2)}
        </h4>
        <div className={cx(css.controls)}>
          <DataContext.Consumer>
            {context => {
              const c = context as IDataContext;
              return (
                <>
                  <button
                    className={cx(css.quantiyButton)}
                    onClick={() => {
                      if (amount > 1) setAmount(amount - 1);
                    }}
                  >
                    -
                  </button>
                  <span className={cx(css.amount)}>
                    {`${amount} `}
                    <Translation name={"PCS"} />
                  </span>
                  <button
                    className={cx(css.quantiyButton)}
                    onClick={() => {
                      setAmount(amount + 1);
                    }}
                  >
                    +
                  </button>
                </>
              );
            }}
          </DataContext.Consumer>
        </div>
      </div>
    );
  };

  return (
    <div className={cx(css.root)}>
      <p className={cx(css.subtitle)}>
        <Translation name={"DOOR_DELIVERY"} />
      </p>

      <div className={cx(css.swiperWrapper)}>
        <VirtualizeSwipeableViews
          enableMouseEvents
          index={currentIndex}
          slideRenderer={renderSlide}
          style={{ padding: "0 calc(50% - 210px)" }}
          slideStyle={{ width: 420, display: "flex", alignItems: "center" }}
          onChangeIndex={(index: number) => {
            setAmount(1);
            updateCurrentIndex(index);
          }}
        />
      </div>
      <DataContext.Consumer>
        {context => {
          const c = context as IDataContext;

          return (
            <>
              <Button
                label={c.trans("ADD_TO_CART")}
                onClick={() => {
                  c.setCartItems(
                    storeItems[
                      mod(
                        currentIndex,
                        storeItems.filter(i => !!i.available).length
                      )
                    ].id,
                    amount
                  );
                  setAmount(1);
                }}
              />
            </>
          );
        }}
      </DataContext.Consumer>
    </div>
  );
};
