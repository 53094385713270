import * as Yup from "yup";

//
// ==================== ORDER DATA ====================
export interface IOrderBillingData {
  address: string;
  city: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  zip: string;
}

export class OrderBillingDataForm {
  static INITIAL_VALUES: IOrderBillingData = {
    address: "",
    city: "",
    country: "",
    email: "",
    name: "",
    phone: "",
    zip: ""
  };

  static VALIDATION = Yup.object().shape<IOrderBillingData>({
    address: Yup.string()
      .required()
      .min(5),
    city: Yup.string()
      .required()
      .min(5),
    country: Yup.string().required(),
    email: Yup.string()
      .required()
      .email(),
    name: Yup.string()
      .required()
      .min(5),
    phone: Yup.string().required(),
    zip: Yup.string().required()
  });
}

//
//
//
export interface IOrderPaymentData {
  cardnumber: string;
  cardexpiration: string;
  cvv?: string;
}

export class OrderBillingPaymentForm {
  static INITIAL_VALUES: IOrderPaymentData = {
    cardnumber: "",
    cardexpiration: "",
    cvv: ""
  };

  static VALIDATION = Yup.object().shape<IOrderPaymentData>({
    cardnumber: Yup.string()
      .required()
      .matches(new RegExp(/\d{4} \d{4} \d{4} \d{4}/)),
    cardexpiration: Yup.string()
      .required()
      .matches(new RegExp(/\d{2}\/\d{2}/)),
    cvv: Yup.string()
      .required()
      .matches(new RegExp(/\d{3}/))
  });

  static VALIDATION_MOBILE = Yup.object().shape<IOrderPaymentData>({
    cardnumber: Yup.string()
      .required()
      .matches(new RegExp(/\d{4} \d{4} \d{4} \d{4}/)),
    cardexpiration: Yup.string()
      .required()
      .matches(new RegExp(/\d{2}\/\d{2}/)),
    cvv: Yup.string().matches(new RegExp(/\d{3}/))
  });
}
