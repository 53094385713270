// .core
import React from "react";
// api
import { SendMail, FillForm, GetFormData } from "api/endpoints/general";
// data
import { DataContext, IDataContext } from "App";
// libraries
import cx from "classnames";
import { Button } from "components";
import { Formik, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";
import MaskedInput from "react-text-mask";
import QRCode from "qrcode.react";
// components
import { Header } from "components/basic/Header/Header";
// styles
import css from "./PaymentPage.module.scss";
// utils
import { OrderBillingPaymentForm } from "utils/forms/CheckoutForm";
// trans
import { Translation } from "components/basic/Translation/Translation";

export default function PaymentPage(props: RouteComponentProps) {
  const isMobile = React.useMemo(() => !!(props.match.params as any).locale, [
    props.match.params
  ]);

  const c = React.useContext(DataContext) as IDataContext;

  const [lInterval, setLInterval] = React.useState<boolean>(false);
  let mailSent = false;
  const setMailSent = (set: boolean) => {
    console.log("mail sent set to", set);
    clearInterval(c.data.interval);

    mailSent = set;
  };

  const handleOrderCompletionFromPhone = (res: any) => {
    console.log("can I even send it?", !mailSent);
    if (!mailSent) {
      setMailSent(true);
      console.log("and sent again");
      SendMail({
        html_content: encodeURI(getTable() || ""),
        send_to: res.raw.email as string,
        subject: c.trans("ORDER_MAIL_SUBJECT"),
        title: c.trans("ORDER_MAIL_SUBJECT")
      }).then(res => {
        if (res!.message.includes("success")) {
          c.setApiFallBack({
            title: c.trans("ORDER_PLACED"),
            hasGoHome: true,
            timeout: 5005,
            visible: true,
            success: true,
            subtitle: c.trans("HANDOVER_DONE_INFO"),
            withLoader: true
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        } else {
          c.setApiFallBack({
            title: c.trans("ERROR_BOUNDRY"),
            hasGoHome: true,
            timeout: 5000,
            visible: true,
            success: false
          });

          setLInterval(false);
        }
      });
    }

    return null;
  };

  const getTable = () => {
    const arr = [
      `<p style="text-align: center; margin: auto; margin-bottom: 30px; margin-top: 32.5px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.  In laoreet ligula sit amet nibh gravida, vitae dapibus augue porttitor. Integer molestie nibh neque.</p>`,
      '<table style="border-collapse: collapse; margin: auto; width: 100%;">',
      "<tbody>"
    ];

    const items = c.getShoppingCartItems();

    items.map((item, ii) => {
      const itemData = c.getShoppingCartItemData(item);

      let parsedId: any = null;

      try {
        parsedId = JSON.parse(itemData!.id);
      } catch (e) {}

      if (itemData) {
        arr.push(
          '<tr style="border: 0px; border-bottom: 1px solid ' +
            (ii === items.length - 1 ? "#DC0840" : "#e5e5e5") +
            '; height: 50px;">'
        );
        arr.push(
          `<td style="border: 0px; height: 50px; color: #001e3c; font-weight: bold; min-width: 25px; padding-left: 10px; padding-right: 10px;">${
            parsedId
              ? `${c.trans(parsedId.color)} ${c.trans(parsedId.name)}`
              : `${c.trans(itemData.LONG_NAME)}`
          }</td>`
        );

        arr.push(
          `<td style="border: 0px; height: 50px; color: #001e3c; font-weight: normal; min-width: 25px; padding-left: 10px; padding-right: 10px;">${
            parsedId
              ? `${c.trans(`GENDER_${parsedId.gender}`)} / ${parsedId.size}`
              : ""
          }</td>`
        );

        arr.push(
          `<td style="border: 0px; height: 50px; color: #001e3c; font-weight: normal; min-width: 25px; padding-left: 10px; padding-right: 10px;">${c
            .data.cartItems[itemData.id] || 0}</td>`
        );

        arr.push(
          `<td style="border: 0px; height: 50px; color: #001e3c; font-weight: bold; min-width: 25px; padding-left: 10px; padding-right: 10px;">${c.trans(
            "CURRENCY_CHARACTER"
          )}${((c.data.cartItems[itemData.id] || 0) * itemData.price).toFixed(
            2
          )}</td>`
        );

        arr.push("</tr>");
      }
    });

    arr.push(
      '<tr style="border: 0px; border-top: 1px solid #DC0840; height: 50px;">'
    );
    arr.push(
      `<td style="border: 0px; height: 50px; color: #DC0840; font-weight: bold; min-width: 25px; padding-left: 10px; padding-right: 10px;">${c.trans(
        "TOTAL"
      )}</td>`
    );

    arr.push(
      `<td style="border: 0px; height: 50px; color: #DC0840; font-weight: normal; min-width: 25px; padding-left: 10px; padding-right: 10px;"></td>`
    );

    arr.push(
      `<td style="border: 0px; height: 50px; color: #DC0840; font-weight: normal; min-width: 25px; padding-left: 10px; padding-right: 10px;"></td>`
    );

    arr.push(
      `<td style="border: 0px; height: 50px; color: #DC0840; font-weight: bold; min-width: 25px; padding-left: 10px; padding-right: 10px;">${c.trans(
        "CURRENCY_CHARACTER"
      )}${c.getShoppingCartTotal(5, 2) || 0}</td>`
    );

    arr.push("</tr>");

    arr.push("</tbody>");
    arr.push("</table>");
    arr.push(
      '<p style="text-align: center; margin: auto; margin-bottom: 10px; margin-top: 32.5px; white-space: pre-wrap;">'
    );
    arr.push(c.trans("DELIVERED_IN"));
    arr.push(
      `<a href="#" style="color: black; font-weight: 600; text-decoration: underline;">${c.trans(
        "TRACK_YOUR_ORDER"
      )}</a>`
    );
    arr.push("</p>");

    return arr.join("");
  };

  React.useEffect(() => {
    console.log(
      "USE EFFECT ",
      c.data.interval,
      (props.match.params as any).hash
    );
    if ((!c.data.interval && (props.match.params as any).hash) || !lInterval) {
      clearInterval(c.data.interval);
      setLInterval(true);
      console.log("SET INTERVALIS");
      c.setInt(
        setInterval(() => {
          console.log("INIT INTERVALIS");
          GetFormData((props.match.params as any).hash).then((res: any) => {
            if (
              res &&
              res.raw &&
              res.raw.cardnumber &&
              res.raw.cardexpiration
            ) {
              if (res.raw.step === 5 && !mailSent) {
                handleOrderCompletionFromPhone(res);
              } else if (res.raw.step === 4) {
                c.setApiFallBack({
                  title: c.trans("DATA_OBTAINED"),
                  timeout: 3000,
                  hasGoHome: false,
                  success: true,
                  visible: true
                });
              }

              if (res.raw.step === 4) {
                c.setUserData(res.raw);
                c.delInt();
              }

              if (res.raw.step < 3) {
                props.history.push(
                  `/checkout/${(props.match.params as any).hash}`
                );
              }
            }
          });
        }, 1000)
      );
    }
  }, [
    c,
    handleOrderCompletionFromPhone,
    isMobile,
    lInterval,
    mailSent,
    props.history,
    props.match.params
  ]);

  return (
    <>
      <div className={cx(css.root)}>
        <Header hideCart hideLangs={isMobile} />
        <div className={cx(css.contentWrapper)}>
          <div>
            <div className={cx(css.cols)}>
              <h1 className={cx(css.title)}>
                <Translation name={"PAYMENT"} />
              </h1>
              <div className={cx(css.scanQr)}>
                {!isMobile && (
                  <>
                    <QRCode
                      className={cx(css.code)}
                      value={`${window.location.origin}/order-form/${
                        c.data.locale
                      }/${(props.match.params as any).hash}`}
                    />
                    <p>
                      <Translation name={"SCAN_QR"} />
                    </p>
                  </>
                )}
              </div>
            </div>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                cvv: OrderBillingPaymentForm.INITIAL_VALUES.cvv,
                cardnumber:
                  c.data.userData.cardnumber ||
                  OrderBillingPaymentForm.INITIAL_VALUES.cardnumber,
                cardexpiration:
                  c.data.userData.cardexpiration ||
                  OrderBillingPaymentForm.INITIAL_VALUES.cardexpiration
              }}
              isInitialValid={false}
              validationSchema={OrderBillingPaymentForm.VALIDATION}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                const rgf: any = await GetFormData(
                  (props.match.params as any).hash as any
                );

                let data = {} as any;

                if (rgf && rgf.raw) {
                  data = {
                    ...rgf.raw
                  };
                }

                data = {
                  ...data,
                  ...values,
                  qrcodehash: (props.match.params as any).hash,
                  step: 5
                };

                delete data.cvv;

                const r = await FillForm({ ...(data as any) });
                console.log("can i even send it from formik?", !mailSent);

                if (r && r.status === "success" && !mailSent) {
                  console.log("aaand sent again.. from formik");
                  setMailSent(true);

                  SendMail({
                    html_content: encodeURI(getTable() || ""),
                    send_to: data.email as string,
                    subject: c.trans("ORDER_MAIL_SUBJECT"),
                    title: c.trans("ORDER_MAIL_SUBJECT")
                  }).then(res => {
                    setSubmitting(false);
                    if (res!.message.includes("success")) {
                      c.setApiFallBack({
                        title: c.trans("ORDER_PLACED"),
                        hasGoHome: true,
                        timeout: 5000,
                        visible: true,
                        success: true,
                        subtitle: c.trans("HANDOVER_DONE_INFO"),
                        withLoader: true
                      });
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 5005);
                    } else {
                      c.setApiFallBack({
                        title: c.trans("ERROR_BOUNDRY"),
                        hasGoHome: true,
                        timeout: 5000,
                        visible: true,
                        success: false
                      });

                      setLInterval(false);
                    }
                  });
                } else {
                  c.setApiFallBack({
                    title: c.trans("ERROR_BOUNDRY"),
                    hasGoHome: true,
                    timeout: 5000,
                    visible: true,
                    success: false
                  });

                  setLInterval(false);
                }
              }}
            >
              {({
                values,
                errors,
                submitForm,
                isValid,
                isSubmitting,
                setFieldValue,
                initialValues
              }) => {
                return (
                  <>
                    <div className={cx(css.field, css.fullWidth)}>
                      <label htmlFor="">
                        <Translation name={"CARD_NUMBER"} />
                      </label>
                      <MaskedInput
                        className={cx(css.input, {
                          [css.error]: errors.cardnumber
                        })}
                        defaultValue={initialValues.cardnumber}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        name="cardnumber"
                        onChange={e => {
                          setFieldValue(e.target.name, e.target.value);
                        }}
                      />
                    </div>
                    <div className={cx(css.cols)}>
                      <div className={cx(css.field)}>
                        <label htmlFor="">
                          <Translation name={"CARD_EXPIRATION"} />
                        </label>
                        <MaskedInput
                          className={cx(css.input, {
                            [css.error]: errors.cardexpiration
                          })}
                          defaultValue={initialValues.cardexpiration}
                          mask={[/[0-1]/, /\d/, "/", /\d/, /\d/]}
                          name="cardexpiration"
                          onChange={e => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                      {!isMobile && (
                        <div className={cx(css.field)}>
                          <label htmlFor="">
                            <Translation name={"CVV"} />
                          </label>
                          <MaskedInput
                            className={cx(css.input, {
                              [css.error]: errors.cvv
                            })}
                            defaultValue={initialValues.cvv}
                            mask={[/\d/, /\d/, /\d/]}
                            name="cvv"
                            onChange={e => {
                              setFieldValue(e.target.name, e.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <Button
                      bDisabled={!isValid}
                      bLoading={isSubmitting}
                      label={`${c.trans("PAY")} ${c.trans(
                        "CURRENCY_CHARACTER"
                      )}${c.getShoppingCartTotal(5, 2) || 0}`}
                      onClick={() => {
                        if (isValid) {
                          submitForm();
                        }
                      }}
                    />
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
