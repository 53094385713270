// .core
import React from 'react'
// components
import { InputDefault, IInputDefaultProps } from '../InputDefault/InputDefault'
// libraries
// import cx from 'classnames'
import { ErrorMessage, Field, FieldProps } from 'formik'
// styles
import css from './InputField.module.scss'

interface IInputFieldProps extends Omit<IInputDefaultProps, 'value'> {
  name: string
}

/**
 * Formik's `<Field />` wrapper to our `<InputDefault />`
 *  - handles pairing `value, onChange, onBlur` based on the passed `name` value
 *  - handles displaying `errors` after validation based on the passed `name` value
 *
 * #TODO:
 *  - add support for Input variants
 *      - styling
 *      - binding value changes
 *      - etc.
 */
export const InputField = ({ name, ...otherProps }: IInputFieldProps) => (
  <Field name={name}>
    {(fieldProps: FieldProps) => (
      <div className={css.wInputField}>
        <InputDefault {...otherProps} {...fieldProps.field} />
        <ErrorMessage component="span" name={name} className={css.errorMessage} />
      </div>
    )}
  </Field>
)
