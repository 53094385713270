// .core
import React from "react";
// libraries
import cx from "classnames";
// styles
import css from "./Spinner.module.scss";

export interface ISpinnerProps {
  className?: string;
  size?: number;
  color?: "black" | "white";
}

/**
 * `Spinner` variant of animated loading indicator
 */
export const Spinner = ({ className, size, color }: ISpinnerProps) => {
  return (
    <svg
      className={cx(css.wSpinner)}
      width={size || "45px"}
      height={size || "45px"}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={css.path}
        fill="none"
        stroke={color === "black" ? "#001E3C" : "#fff"}
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );
};
