// List of supported error codes - could be used for generic translations for Toasts
const HTTPCodes = {
  0: "",
  200: "ok",
  201: "created",
  204: "no_content",
  400: "bad_request",
  401: "unauthorized",
  402: "payment_required",
  403: "forbidden",
  404: "not_found",
  408: "request_timeout",
  409: "conflict",
  500: "internal_server_error",
  502: "bad_gateway",
  503: "service_unavailable"
};

/**
 * Base API endpoint URL
 */
const BASE_URL: string = "https://rb-demo.ambitas.sk/api/v1";
/**
 * Type of fetching method used by the app
 */
const FETCH_TYPE: "fetch" | "graphQL" = "fetch";

/**
 * Configuration for the unified `API()` used by every single API call declared within `api/endpoints`
 */
interface IAPICallConfig<IBody = object, IOptions = {}>
  extends /* RequestInit, */ IAPICallParams<IOptions> {
  // #TODO: technically `options` should be part of body
  bAuthentificate?: boolean;
  body?: IBody;
  /**
   * Enpoint's URL
   */
  URL: string;
  /**
   * collection of used HTTP request types supported by `fetch()`
   */
  method: "GET" | "POST" | "PATCH" | "DELETE";
}

/**
 * Custom parameters passed to every API call declared within `api/endpoints`
 */
export interface IAPICallParams<IOptions> {
  /**
   * Fallback value to return if the API call fails
   */
  fallBackValue?: any;
  /**
   * Additional options - may vary based on API call
   */
  options?: IOptions;
  /**
   * Config for custom Toast behaviour
   *
   * 1st param is message
   *
   * 2nd param is duration
   */
  toast?: [string, number];
}

export interface IAPIResponse<T> {
  /**
   * `data` of the response
   */
  content?: T;
  /**
   * Whether the API call succeeded or not
   */
  success: boolean;
  /**
   * Short message (from BE) describing the API call's status (whether it succeeded or failed)
   */
  message: string;
  /**
   * Status code of the API call
   */
  status: keyof typeof HTTPCodes;
}

/**
 * generic unified method for executing API calls
 * @param apiCallParams configuration & payload data for the API call
 */
export async function API<T, U>(
  apiCallParams: IAPICallConfig
) /* :  Promise<IAPIResponse<T> */ {
  if (FETCH_TYPE === "fetch") {
    const { URL, method, bAuthentificate, body, toast } = apiCallParams;
    // HTTP Request headers
    const headers: Headers | Record<string, string> = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=utf-8"
    };

    try {
      // attach `accessToken` to the `headers`
      if (bAuthentificate) {
        const accessToken: string | null = localStorage.getItem("accessToken");
        if (accessToken) headers["X-API-AUTH"] = accessToken;
      }

      // send the fetch HTTP request & JSON.parse the response
      const responseRaw: Response = await fetch(BASE_URL + URL, {
        body: body ? JSON.stringify(body) : undefined, // body isnt allowed for GET requests
        headers,
        method
      });

      const resp = await responseRaw.json();
      const { data, message, status, success } = resp;

      // Showing a Toast
      if (toast) {
        //   showToast(toast[0] || message, toast[1] || gData.TOAST_OK)       //          #TODO
      }

      // token expired or is invalid (authentification failed)
      if (status === 40) {
        //  KICK THE USER OUT OF THE APP AND FORCE A RE-LOGIN
        //  kickUser()
      }

      if (status >= 500) {
        //   showToast('Issue occured on the server side ! \n' + message, gData.TOAST_OK)

        return {
          message: "Issue occured on the server side !",
          status: status,
          success: false
        } as IAPIResponse<T>;
      }

      return {
        raw: resp,
        content: data, // ? (data as T) : (res as T),        // #CHECK
        message,
        status,
        success
      };
    } catch (err) {
      const errString = JSON.stringify(err);

      // showing a Toast with received error message
      // Store.toast(errString, gData.TOAST_ERR)

      return {
        content: err,
        message: errString,
        status: 0,
        success: false
      } as IAPIResponse<T>;
    }
  } else {
    // use GraphQL
  }
}
