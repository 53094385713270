// .core
import React from "react";
// libraries
import cx from "classnames";
// styles
import css from "./HeaderButton.module.scss";

interface IHeaderButtonProps {
  active?: boolean;
  customIconStyle?: React.CSSProperties;
  disabled?: boolean;
  icon: string;
  iconClassName?: string;
  text?: string;
  onClick: () => void;
}

export const HeaderButton = ({
  active,
  customIconStyle,
  disabled,
  icon,
  iconClassName,
  text,
  onClick
}: IHeaderButtonProps) => (
  <button
    className={cx(css.root, { [css.active]: active, [css.hasText]: text })}
    onClick={!disabled ? onClick : undefined}
  >
    <p>{text}</p>
    <img
      alt={`Icon_${icon}`}
      className={cx({ [css.disabledIcon]: !!disabled }, iconClassName || "")}
      src={require(`assets/icons/svg/${icon}.svg`)}
      style={customIconStyle}
    />
  </button>
);
