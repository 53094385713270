// .core
import React from "react";
// components
import { Icon } from "../Icon/Icon";
// i18n
// import { t } from 'i18n'
// libraries
import cx from "classnames";
// styles
import css from "./NoData.module.scss";
// utils
import { TIcon } from "utils";

export interface INoDataProps {
  className?: string;
  icon?: TIcon;
  message?: string;
}

export const NoData: React.FC<INoDataProps> = ({
  className,
  icon = "info"
}: // message = t.NO_DATA,
INoDataProps) => (
  <div className={cx(css.wNoData, className)}>
    {/* ICON */}
    <Icon name={icon} />

    {/* MESSAGE */}
    {/* <span>{message}</span> */}
  </div>
);
