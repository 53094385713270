// translations
// import { t } from "i18n/index";
import { useContext } from "react";
import { DataContext, IDataContext, IDataState } from "App";

export interface IStoreItem {
  available?: boolean;
  id: string;
  image: string;
  LONG_NAME: string;
  NAME: string;
  price: number;
  special?: {
    color: string;
    gender: string;
    size: string;
  };
}

function generateTees() {
  const sizes: string[] = ["S", "M", "L", "XL", "XXL"];
  const colors: string[] = ["red", "blue", "white"];
  const genders: string[] = ["M", "F"];

  const tees: IStoreItem[] = [];

  sizes.map(size => {
    colors.map(color => {
      genders.map(gender => {
        tees.push({
          id: `{"name": "TEE", "size": "${size.toUpperCase()}", "gender": "${gender.toUpperCase()}", "color": "${color.toUpperCase()}"}`,
          image: require(`assets/images/tee@${color}.png`),
          NAME: `${color.toUpperCase()}_${"TEE"}`,
          LONG_NAME: `${color.toUpperCase()}_${"TEE"}`,
          price: 6
        });
      });
    });
  });

  return tees;
}

export const storeItems = [
  {
    available: true,
    id: "rb4pack",
    image: require("assets/images/redBullPack@4pack-min.jpg"),
    LONG_NAME: "STORE_ITEMS.REDBULL_4PACK.LONG_NAME",
    NAME: "STORE_ITEMS.REDBULL_4PACK.NAME",
    price: 6
  },
  {
    available: true,
    id: "rb6pack",
    LONG_NAME: "STORE_ITEMS.REDBULL_6PACK.LONG_NAME",
    NAME: "STORE_ITEMS.REDBULL_6PACK.NAME",
    image: require("assets/images/redBullPack@6pack-min.jpg"),
    price: 9
  },
  {
    available: true,
    id: "rb24pack",
    image: require("assets/images/redBullPack@24pack-min.jpg"),
    LONG_NAME: "STORE_ITEMS.REDBULL_24PACK.LONG_NAME",
    NAME: "STORE_ITEMS.REDBULL_24PACK.NAME",
    price: 36
  },
  ...generateTees()
];
