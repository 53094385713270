// .core
import React from "react";
// components
import { Icon } from "../../../Icon/Icon";
// libraries
import cx from "classnames";
// i18n
// import { t } from 'i18n'
// styles
import css from "./InputDefault.module.scss";
// utils
import { TIcon } from "utils";
// declarations
type TValue = string | number;

interface IInputIcon {
  className?: string;
  name: TIcon;
  position: "labelLeft" | "labelRight"; // 'inputLeft' | 'inputRight' |
}

export interface IInputDefaultProps {
  bAutoFocus?: boolean;
  bClearable?: boolean;
  bDisabled?: boolean;
  bReadOnly?: boolean;
  bRequired?: boolean;
  bTextArea?: boolean;

  className?: string;
  classNameError?: string;
  classNameInput?: string;
  classNameLabel?: string;

  error?: string;
  id?: string;
  label?: string;
  icon?: IInputIcon;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  name?: string;
  placeholder?: string;
  value: TValue;
  type?: "email" | "text" | "number" | "password";
  onBlur?(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  onChange?(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
}

/** #TODO: turn into `React.FC`
 * Default Input component
 */
export const InputDefault = ({
  bAutoFocus,
  bClearable,
  bDisabled,
  bReadOnly,
  bRequired,
  bTextArea,

  className,
  classNameError,
  classNameInput,
  classNameLabel,

  error,
  icon,
  id,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  placeholder = "",
  type = "text",
  value = "",

  onBlur,
  onChange
}: IInputDefaultProps) => {
  const input = React.createRef<HTMLInputElement | HTMLTextAreaElement>();

  const _onClear = () => {
    const event = new Event("input", { bubbles: true });

    if (input.current && onChange) {
      input.current.value = "";
      input.current.dispatchEvent(event);
      onChange(event as any);
    }
  };

  return (
    <div className={cx(css.wInput, className)}>
      {/* LABEL - container */}
      {label && (
        <div className={css.wLabel}>
          {/* ICON - left from label */}
          {icon && icon.position === "labelLeft" && <Icon {...icon} />}

          {/* LABEL - text */}
          <label
            className={cx(
              css.tLabel,
              bRequired && "gInputRequired",
              classNameLabel
            )}
            htmlFor={id || "input"}
          >
            {label}
          </label>

          {/* ICON - right from label */}
          {icon && icon.position === "labelRight" && <Icon {...icon} />}
        </div>
      )}

      {/* TEXT AREA / INPUT FIELD */}
      {bTextArea ? (
        <textarea
          ref={input as React.Ref<HTMLTextAreaElement>}
          autoFocus={bAutoFocus}
          className={cx(css.fInput, classNameInput)}
          disabled={bDisabled}
          id={id}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          readOnly={bReadOnly}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <input
          ref={input as React.Ref<HTMLInputElement>}
          autoFocus={bAutoFocus}
          className={cx(css.fInput, classNameInput)}
          disabled={bDisabled}
          id={id}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          name={name}
          placeholder={placeholder}
          readOnly={bReadOnly}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      {/* X - clear icon */}
      {bClearable && (
        <Icon classNameWrapper={css.iClear} name="x" onClick={_onClear} />
      )}

      {/* ERROR MESSAGE */}
      {error && <span className={cx(css.tError, classNameError)}>{error}</span>}
    </div>
  );
};
