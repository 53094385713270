// .core
import React from "react";
import ReactDOM from "react-dom";
// components
import { Button } from "../../../../basic/Button/Button";
import { Icon } from "../../../../basic/Icon/Icon";
// i18n
// import { t } from 'i18n'
// libraries
import cx from "classnames";
// styles
import css from "./DialogDefault.module.scss";

export interface IDialogDefaultProps {
  bOpen?: boolean;
  className?: string;
  classNameContent?: string;
  title?: string;
  onSubmit?(): void;
  onToggle?(bOpen?: boolean): void;
}

export class DialogDefault extends React.PureComponent<IDialogDefaultProps> {
  static defaultProps: IDialogDefaultProps = {
    title: "t.DIALOG"
  };

  // =============== I N I T ===============
  //   componentDidMount() {}
  //   componentWillUnmount() {}

  // =============== E V E N T S ===============
  onToggle = () => {
    const { bOpen, onToggle } = this.props;
    onToggle?.(!bOpen);
  };

  render() {
    const {
      bOpen,
      children,
      className,
      classNameContent,
      title,
      onToggle,
      onSubmit
    } = this.props;
    // if (!bOpen) return null;

    return ReactDOM.createPortal(
      <div className={cx(css.wBackground, { [css.hidden]: !bOpen })}>
        <div className={cx(css.wDialog, className)}>
          {/* HEADER */}
          <div className={cx(css.wHeader)}>
            <span>{title}</span>
            <img
              className={cx(css.close)}
              src={require("assets/icons/svg/close@large.svg")}
              onClick={this.onToggle}
            />
          </div>

          {/* CONTENT */}
          <div className={cx(css.wContent, classNameContent)}>{children}</div>

          {/* FOOTER */}
          <div className={cx(css.wFooter)}>
            {/* <Button.Cancel onClick={this.onToggle} /> */}
            <Button.Submit
              onClick={() => {
                if (onSubmit) onSubmit();
              }}
            />
          </div>
        </div>
      </div>,
      document.body
    );
  }
}
