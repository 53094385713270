// .core
import React from "react";
// components
import { Button } from "components";
import { Header } from "../../components/basic/Header/Header";
// data
import { DataContext, IDataContext } from "App";
// libraries
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
// styles
import css from "./TeePage.module.scss";
// trans
import { Translation } from "components/basic/Translation/Translation";

export default function TeePage(props: RouteComponentProps) {
  const [tees, setTees] = React.useState<{
    white: number;
    blue: number;
    red: number;
  }>({
    white: 1,
    blue: 2,
    red: 3
  });

  const getCenteredTee = () => {
    return Object.keys(tees).filter((k: string) => (tees as any)[k] === 2)[0];
  };

  const centerTee = (color: "white" | "red" | "blue") => {
    const centeredTee = getCenteredTee();

    if (!centeredTee) return;

    const teesCopy = { ...tees };

    const temp = teesCopy[color];
    teesCopy[color] = (teesCopy as any)[centeredTee];
    (teesCopy as any)[centeredTee] = temp;

    setTees(teesCopy);
  };

  const getClassFromKey = (key: string) => {
    const index = (tees as any)[key];

    switch (index) {
      case 1:
        return css.left;
      case 2:
        return css.active;
      case 3:
        return css.right;
    }
  };

  const setCurrentQt = (op: -1 | 0 | 1) => {
    if (op > 0) {
      setQt(qt + 1);
    } else if (op === 0) {
      setQt(1);
    } else if (qt > 1) {
      setQt(qt - 1);
    }
  };

  const [gender, setGender] = React.useState<"M" | "F">("M");
  const [size, setSize] = React.useState<string>("M");
  const [qt, setQt] = React.useState<number>(1);

  const sizes: string[] = ["S", "M", "L", "XL", "XXL"];
  const colors: string[] = ["red", "blue", "white"];

  return (
    <DataContext.Consumer>
      {context => {
        const c = context as IDataContext;

        return (
          <div className={cx(css.root)}>
            <Header showCart onGoBack={() => props.history.push("/selfie")} />
            <div className={cx(css.contentWrapper)}>
              <div className={cx(css.content)}>
                <p className={cx(css.subtitle)}>
                  <Translation name={"TEE_SUBTITLE"} />
                </p>
                <div className={cx(css.shirts)}>
                  {colors.map(color => (
                    <div
                      key={color}
                      className={cx(css.shirt, getClassFromKey(color))}
                    >
                      <img
                        alt=""
                        className={cx(css.selfie)}
                        src={
                          typeof c.data.userPhoto === "string"
                            ? c.data.userPhoto
                            : undefined
                        }
                      />
                      <img
                        alt=""
                        className={cx(css.shirtImg)}
                        src={require(`assets/images/tee@${color}.png`)}
                        onClick={() => {
                          centerTee(color as any);
                        }}
                      />
                    </div>
                  ))}
                </div>

                <div className={cx(css.controls)}>
                  {/* džendžer */}
                  <div className={cx(css.buttonGroup)}>
                    <span
                      className={cx(css.btn, { [css.active]: gender === "M" })}
                      onClick={() => {
                        setGender("M");
                      }}
                    >
                      <Translation name={"GENDER_M"} />
                    </span>
                    <span
                      className={cx(css.btn, { [css.active]: gender === "F" })}
                      onClick={() => {
                        setGender("F");
                      }}
                    >
                      <Translation name={"GENDER_F"} />
                    </span>
                  </div>
                  {/* sizes */}
                  <div className={cx(css.sizes)}>
                    {sizes.map(shirtSize => {
                      return (
                        <span
                          key={shirtSize}
                          className={cx(css.sizeBtn, {
                            [css.active]: shirtSize === size
                          })}
                          onClick={() => {
                            setSize(shirtSize);
                          }}
                        >
                          {shirtSize}
                        </span>
                      );
                    })}
                  </div>
                </div>
                {/* title */}
                <h2 className={cx(css.productName)}>
                  <Translation name={getCenteredTee().toUpperCase()} />{" "}
                  <Translation name={"TEE"} />
                </h2>
                {/* TODO: */}
                <h3 className={cx(css.productPrice)}>$6.00</h3>
                <div className={cx(css.amountControls)}>
                  <button
                    className={cx(css.amountButton)}
                    onClick={() => {
                      setCurrentQt(-1);
                    }}
                  >
                    -
                  </button>
                  <span className={cx(css.amount)}>
                    {/* {c.data.cartItems[
                      `{"name": "TEE", "size": "${size.toUpperCase()}", "gender": "${gender.toUpperCase()}", "color": "${getCenteredTee().toUpperCase()}"}`
                    ] || 0} */}
                    {qt} <Translation name={"PCS"} />
                  </span>
                  <button
                    className={cx(css.amountButton)}
                    onClick={() => {
                      setCurrentQt(1);
                    }}
                  >
                    +
                  </button>
                </div>
                <Button
                  label={c.trans("ADD_TO_CART")}
                  onClick={() => {
                    c.setCartItems(
                      `{"name": "TEE", "size": "${size.toUpperCase()}", "gender": "${gender.toUpperCase()}", "color": "${getCenteredTee().toUpperCase()}"}`,
                      qt
                    );
                    setQt(1);
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    </DataContext.Consumer>
  );
}
