export const sk = {
  // A
  ADD_TO_CART: "Pridať do košíka",
  ADDRESS: "Adresa",

  // B
  BACK: "Naspäť",
  BLUE: "Modré",
  BLUE_TEE: "Modré Tričko",

  // C
  CANCEL: "Zrušiť",
  CARD_EXPIRATION: "Dátum expirácie",
  CARD_NUMBER: "Číslo karty",
  CART: "Košík",
  CHECKOUT: "Objednávka",
  CITY: "Mesto",
  CLOSE: "Zatvoriť",
  CLOSE_APP: "Zatvoriť aplikáciu",
  CONFIRM: "Potvrdiť",
  CONTINUE: "Pokračovať",
  COULDNT_SEND_EMAIL: "Nepodarilo sa odoslať e-mail",
  COUNTRY: "Krajina",
  CREATE_SELFIE_TEE: "Vytvoriť selfie tričko",
  CREATE_TEE: "Vytvoriť tričko",
  CURRENCY_CHARACTER: "€",
  CVV: "CVV",

  // D
  DATA_OBTAINED: "Dáta boli úspešne\r\nsynchronizované s Vašim smartfónom",
  DELIVERED_IN:
    "Vaša objednávka bude doručená do 4 pracovných dní.\r\nTu môžete ",
  DELETE: "Zmazať",
  DELETE_PROMPT: "Ste si istí, že chcete danú položku odstrániť z košíka?",
  DELIVERY_PRICE: "+ €5 Cena Doručenia",
  DIALOG: "Dialog",
  DOOR_DELIVERY: "Doručíme Vám krídla Vášho života\r\npriamo k Vašim dverám",
  DOWNLOAD: "Stiahnuť",

  // E
  EMAIL: "E-mail",
  EMAIL_SENT: "E-mail úspešne odoslaný",
  EMPTY_CART: "Váš nákupný košík je prázdny.",
  END_PUZZLE_DIALOG_TITLE: "Ste si istí, že chcete ukončiť túto hru?",
  END_PUZZLE_DIALOG_TEXT:
    "Aktuálny stav hry nebude uložený - prídete tým teda o možnosť výhry plechovky RedBull.",
  ENTER_VALUE: "Zadajte hodnotu",
  ERROR_BOUNDRY: "Ups! Niečo sa pokazilo.",

  // F
  FORGOTTEN_PASSWORD: "Zabudnuté heslo",

  // G
  GENDER_F: "Dámske",
  GENDER_M: "Pánske",

  // H
  HANDOVER: "Previesť",
  HANDOVER_PAYMENT: "Vzdialená Platba",
  HANDOVER_PAYMENT_SUBTITLE: "Synchronizujte Vaše dáta\r\ns Totem POS",
  HANDOVER_PAYMENT_SYNCED:
    "Vaše dáta boli\r\nsynchronizované s Totem POS.\r\nČaká sa na Vaše potvrdenie\r\nna obrazovke.",
  HANDOVER_DONE:
    "Objednávka bola vytvorená.\r\nĎakujeme, že využívate naše služby!\r\n",
  HANDOVER_DONE_INFO:
    "Všetky informácie o objednávke vám zašleme priamo na vašu e-mailovú adresu.",
  HOME_TILES: {
    PUZZLE: {
      TITLE: "Puzzle",
      SUBTITLE:
        "Skúste poskladať puzzle v rámci časového limitu a vyhrajte plechovku RedBull-u."
    },
    SHOP: {
      TITLE: "Obchod",
      SUBTITLE:
        "Objednajte si svoje obľúbené RedBull produkty\na majte ich doručené až domov."
    },
    SELFIE: {
      TITLE: "Selfie Tričko",
      SUBTITLE:
        "Urobte si selfie! Môžete si nechať vytvoriť tričko s vašou fotkou, alebo si ju poslať."
    },
    VIDEO: {
      TITLE: "Akčné Video",
      SUBTITLE: "Pozrite si naše najnovšie video\nFilipa Polca v Brazílii."
    }
  },
  // I
  // J
  // K
  // L
  LANGUAGES: "Jazyk",
  LOGIN: "Prihlásiť sa",
  LOGOUT: "Odhlásiť sa",

  // M
  MAIL_PUZZLE_SUBJECT: "Práve ste vyhrali plechovku RedBull-u!",
  MAIL_PUZZLE_TEXT:
    "Použite tento QR kód v akomkoľvek obchode pre zaplatenie za plechovku. Je to jednoduché, stačí ho ukázať obsluhe pri pokladni.",
  MAIL_SELFIE_SUBJECT: "Tu je Vaša COOL RedBull selfie!",

  // N
  NAME: "Meno",
  NEXT: "Ďalej",
  NO_DATA: "Pre túto sekciu neexistujú žiadne položky", // e.g.: záznamy

  // O
  ORDER_DATA_SENT: "Dáta úspešne odoslané",
  ORDER_MAIL_SUBJECT: "Spracovanie objednávky",
  ORDER_PLACED: "Vaša objednávka bola vytvorená",

  // P
  PASSWORD: "Heslo",
  PAY: "Zaplatiť",
  PAYMENT: "Platba",
  PAYMENT_DONE_SUBTITLE:
    "Zo strany Vašej banky sme evidovali Vašu platbu ako uhradenú. Budeme Vás notifikovať hneď ako odošleme Vašu objednávku.",
  PAYMENT_DONE_TITLE: "Vaša platba\r\nbola úspešne uhradená!",
  PAYMENT_NOTICE:
    "Pole CVV môžete nechať prázdne v závislosti od toho, kde si želáte dokončiť platbu.",
  PCS: "ks",
  PHONE: "Telefón",
  PROCEED_TO_CHECKOUT: "Vytvoriť objednávku",
  PROCEED_TO_PAYMENT: "Prejsť na platbu",
  PUZZLEFINISH_SUBTITLE: `Dajte nám Vašu e-mailovú adresu a my Vám na ňu zašleme QR kód, ktorým môžete v obchode zaplatiť za plechovku RedBull-u. Je to jednoduché! Kód stačí ukázať pri pokladni v akomkoľvek obchode.`,
  PUZZLEFINISH_SENT:
    "Zaslali sme Vám QR kód\r\npre Vašu plechovku RedBull zdarma.\r\nUžite si ju!",
  PUZZLEFINISH_TITLE: "Vyhrali ste\r\nplechovku RedBull-u",

  // Q
  // R
  RED: "Červené",
  RED_TEE: "Červené Tričko",
  REGISTER: "Registrovať sa",

  // S
  SAVE: "Uložiť",
  SCAN_QR: "Naskenujte QR kód a vyplňte formulár z Vášho smartfónu",
  SELFIE_MAIL_TEXT:
    "Zdravíme, tu je Vaša COOL selfie od RedBull Teamu! Nezabudnite ju zdieľať na sociálnych sieťach s Vašimi kamarátmi!",
  SELFIE_SENT:
    "Vaša COOL selfie\r\nbola odoslaná na Váš e-mail.\r\nNeváhajte ju zdieľať s Vašimi kamarátmi!",
  SELFIE_SUBTITLE:
    "Dajte nám Vašu e-mailovú adresu a my Vám zašleme Vašu cool selfie!",
  SELFIE_TITLE: "Poslať selfie\r\nna e-mail",
  SEND_BY_EMAIL: "Poslať e-mailom",
  SEND_EMAIL: "Poslať e-mail",
  SKIP: "Preskočiť",
  STORE_ITEMS: {
    REDBULL_4PACK: {
      NAME: "4 Balenie",
      LONG_NAME: "RedBull 4 Balenie"
    },
    REDBULL_6PACK: {
      NAME: "6 Balenie",
      LONG_NAME: "RedBull 6 Balenie"
    },
    REDBULL_24PACK: {
      NAME: "24 Balenie",
      LONG_NAME: "RedBull 24 Balenie"
    }
  },
  SUBMIT: "Potvrdiť",
  SUBTOTAL: "Medzisúčet",

  // T
  TAKE_PHOTO: "Urobiť fotku",
  TEE: "Tričko",
  TEE_SUBTITLE: "Doručíme Vám krídľa Vášho života\r\npriamo k Vašim dverám",
  TOTAL: "Spolu",
  TRACK_YOUR_ORDER: "sledovať jej stav.",

  // U
  // V
  // W
  WHITE: "Biele",
  WHITE_TEE: "Biele Tričko",

  // X
  // Y
  YES: "Áno",
  YOUR_TIME: "Váš čas",

  // Z
  ZIP: "PSČ"
};
