// .core
import React from "react";
// components
import {
  ButtonDefault,
  IButtonDefaultProps
} from "../ButtonDefault/ButtonDefault";
// i18n
// import { t } from 'i18n'
// libraries
import cx from "classnames";
// styles
import css from "./ButtonCancel.module.scss";
import { DataContext, IDataContext } from "App";

interface IButtonCancelProps extends IButtonDefaultProps {}

/**
 * `Cancel` variant of the default button
 */
export const ButtonCancel = ({
  className,
  label,
  ...otherProps
}: IButtonCancelProps) => {
  const c = React.useContext(DataContext) as IDataContext;

  return (
    <ButtonDefault
      {...otherProps}
      className={cx(css.wButtonCancel, className)}
      label={label || c.trans("CANCEL")}
      type="reset"
    />
  );
};
