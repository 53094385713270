// .core
import React from "react";
// api
import { GetFormData, FillForm } from "api/endpoints/general";
// data
import { DataContext, IDataContext } from "App";
// libraries
import cx from "classnames";
import { Button } from "components";
import { Formik, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";
import QRCode from "qrcode.react";
// components
import { Header } from "components/basic/Header/Header";
// styles
import css from "./CheckoutPage.module.scss";
// trans
import { Translation } from "components/basic/Translation/Translation";
// utils
import { OrderBillingDataForm } from "utils/forms/CheckoutForm";

export default function CheckoutPage(props: RouteComponentProps) {
  const dataContext = React.useContext(DataContext) as IDataContext;

  const isMobile = !!(props.match.params as any).locale;

  const [localSubmit, setLocalSubmit] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isMobile) {
      if (dataContext.data.locale !== (props.match.params as any).locale) {
        dataContext.setLocale((props.match.params as any).locale);
      }
    } else {
      if (!dataContext.data.interval && (props.match.params as any).hash) {
        dataContext.setInt(
          ((window as any).interval = setInterval(() => {
            GetFormData((props.match.params as any).hash).then((res: any) => {
              if (res && res.raw && res.raw.email && !localSubmit) {
                dataContext.setApiFallBack({
                  title: dataContext.trans("DATA_OBTAINED"),
                  timeout: 3000,
                  hasGoHome: false,
                  success: true,
                  visible: true
                });
                dataContext.setUserData(res.raw);
                dataContext.delInt();

                if (res.raw.step >= 3) {
                  props.history.push(
                    `/payment/${(props.match.params as any).hash}`
                  );
                }
              }
            });
          }, 1000))
        );
      }
    }
  }, [dataContext, isMobile, localSubmit, props.history, props.match.params]);

  return (
    <DataContext.Consumer>
      {context => {
        const c = context as IDataContext;

        return (
          <>
            <div className={cx(css.root)}>
              <Header
                hideCart
                hideLangs={isMobile}
                onGoBack={
                  !isMobile
                    ? () => {
                        c.clearUserData();
                        clearInterval(c.data.interval);
                        props.history.goBack();
                      }
                    : undefined
                }
              />
              <div className={cx(css.contentWrapper)}>
                <div>
                  <div className={cx(css.cols)}>
                    <h1 className={cx(css.title)}>
                      <Translation name={"CHECKOUT"} />
                    </h1>
                    <div className={cx(css.scanQr)}>
                      {!isMobile && (
                        <>
                          <QRCode
                            className={cx(css.code)}
                            value={`${window.location.origin}/order-form/${
                              c.data.locale
                            }/${(props.match.params as any).hash}`}
                          />
                          <p>
                            <Translation name={"SCAN_QR"} />
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      ...OrderBillingDataForm.INITIAL_VALUES,
                      ...c.data.userData
                    }}
                    isInitialValid={Object.keys(c.data.userData).length >= 7}
                    validationSchema={OrderBillingDataForm.VALIDATION}
                    onSubmit={values => {
                      console.log(values);
                    }}
                  >
                    {({ values, isValid, setSubmitting }) => (
                      <>
                        <div className={cx(css.cols)}>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"EMAIL"} />
                            </label>
                            <Field className={cx(css.input)} name="email" />
                          </div>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"PHONE"} />
                            </label>
                            <Field className={cx(css.input)} name="phone" />
                          </div>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"NAME"} />
                            </label>
                            <Field className={cx(css.input)} name="name" />
                          </div>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"ADDRESS"} />
                            </label>
                            <Field className={cx(css.input)} name="address" />
                          </div>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"CITY"} />
                            </label>
                            <Field className={cx(css.input)} name="city" />
                          </div>
                          <div className={cx(css.field)}>
                            <label htmlFor="">
                              <Translation name={"ZIP"} />
                            </label>
                            <Field className={cx(css.input)} name="zip" />
                          </div>
                        </div>
                        <div className={cx(css.field, css.fullWidth)}>
                          <label htmlFor="">
                            <Translation name={"COUNTRY"} />
                          </label>
                          <Field className={cx(css.input)} name="country" />
                        </div>
                        <Button
                          bDisabled={!isValid}
                          label={`${c.trans("PROCEED_TO_PAYMENT")} ${
                            !isMobile
                              ? `${c.trans(
                                  "CURRENCY_CHARACTER"
                                )}${c.getShoppingCartTotal(5) || 0}`
                              : ""
                          } `}
                          onClick={async () => {
                            setSubmitting(true);
                            setLocalSubmit(true);

                            const res = (await FillForm({
                              ...values,
                              qrcodehash: (props.match.params as any).hash,
                              cardexpiration: "",
                              cardnumber: "",
                              step: 3
                            })) as any;

                            if (isValid && res && res.status === "success") {
                              c.setUserData({
                                ...c.data.userData,
                                ...values
                              } as any);

                              c.delInt();
                              c.setInt(null);

                              props.history.push(
                                `/payment/${(props.match.params as any).hash}`
                              );
                            } else {
                              setSubmitting(false);
                            }
                          }}
                        />
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </DataContext.Consumer>
  );
}
