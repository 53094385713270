// .core
import React from "react";
// components
import { Header } from "components/basic/Header/Header";
// libraries
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { ShopSwiper } from "components/basic/ShopSwiper/ShopSwiper";
// styles
import css from "./ShopPage.module.scss";

export default function ShopPage(props: RouteComponentProps) {
  return (
    <div className={cx(css.root)}>
      <Header showCart onGoBack={() => props.history.push("/")} />
      <ShopSwiper {...props} />
    </div>
  );
}
