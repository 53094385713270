import React from "react";
import { DataContext, IDataContext } from "App";

export const Translation = ({ name }: { name: string }) => (
  <DataContext.Consumer>
    {context => {
      const c = context as IDataContext;
      return c.trans(name);
    }}
  </DataContext.Consumer>
);
